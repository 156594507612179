import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({
  item,
  index,
  updateBlockedIpHandler
}) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {index + 1}
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.ip_address}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.createdAt)}
      </div>
      <div className={styles.col}>
        <button className={cn("button", styles.rejectButton)} type="button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            updateBlockedIpHandler(item?.id);
          }}
        >
          <span>Remove</span>
        </button>
      </div>
    </div>
  );
};

export default Row;
