import React, { useCallback, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import styles from "./Modal.module.sass";
import Icon from "../Icon";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";

const NewModal = ({ outerClassName, children, showModal, handleCloseModal }) => {

    const escFunction = useCallback(
        (e) => {
            if (e.keyCode === 27) {
                handleCloseModal();
            }
        },
        [handleCloseModal]
    );

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    useEffect(() => {
        if (showModal) {
            const target = document.querySelector("#modal");
            disableBodyScroll(target);
        } else {
            clearAllBodyScrollLocks();
        }
    }, [showModal]);


    return (
        <div>
            {showModal && (
                <div id="modal" className={styles.modal}>
                    <div className={cn(styles.outer, outerClassName)}>
                        <OutsideClickHandler onOutsideClick={handleCloseModal}>
                            {children}
                            <button className={styles.close} onClick={handleCloseModal}>
                                <Icon name="close" size="20" />
                            </button>
                        </OutsideClickHandler>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewModal;