import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Row from "./Row";

const RecentPost = ({
  className,
  profileInfo,
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="IP Address"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>IP Address</div>
          </div>
          {Object(profileInfo?.ip_addresses?.IP)?.map((x, index) => (
            <Row
              item={x}
              key={index}
              index={index}
            />
          ))}
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
