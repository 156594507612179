import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import LoaderScreen from "../../../components/LoaderScreen";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { addNotification } from "../../../components/Notification";
import CustomDropdown from "../../../components/CustomDropdown";
import Editor from "../../../components/Editor";

const NameAndDescription = ({
  handleChange,
  coinsId,
  setCoinsId,
  loading,
  setLoading,
  selected,
  coinsNetwork,
  getCoinsNetwork,
  className,
  state,
  updateCoinHandler,
  fields,
  addField,
  removeField,
  handleInputChange,
  spreadValue,
  setSpreadValue,
  convertSpreadValue,
  setConvertSpreadValue
}) => {
  const statusOptions = ["Enable", "Disable"];
  const enableDepositOptions = [{ id: 1, name: 'Enable' }, { id: 0, name: 'Disable' }];
  const enableWithdrawOptions = [{ id: 1, name: 'Enable' }, { id: 0, name: 'Disable' }];
  const maintenanceModeOption = ["Yes", "No"];
  const [status, setStatus] = useState(state?.status === 0 ? "Disable" : "Enable");
  const [maintenanceMode, setMaintenanceMode] = useState(state?.maintenanceMode === 1 ? "Yes" : "No");
  const animatedComponents = makeAnimated();
  const [decimalPrecision, setDecimalPrecision] = useState(state.decimalPrecision);
  const [coinName, setCoinName] = useState(state.name);
  const [coinMaxFee, setCoinMaxFee] = useState(state.maxFee);
  const [coinMinFee, setCoinMinFee] = useState(state.minFee);
  const [coinMinBalance, setCoinMinBalance] = useState(state.minBalance);
  const [enableDepositText, setEnableDepositText] = useState(state?.enable_deposit === 1 ? enableDepositOptions[0].name : enableDepositOptions[1].name);
  const [enableDepositValue, setEnableDepositValue] = useState(state?.enable_deposit === 1 ? enableDepositOptions[0].id : enableDepositOptions[1].id);
  const [enableWithdrawText, setEnableWithdrawText] = useState(state?.enable_withdraw === 1 ? enableWithdrawOptions[0].name : enableWithdrawOptions[1].name);
  const [enableWithdrawValue, setEnableWithdrawValue] = useState(state?.enable_withdraw === 1 ? enableWithdrawOptions[0].id : enableWithdrawOptions[1].id);
  const [coinImage, setCoinImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [coinDescription, setCoinDescription] = useState(state?.description);

  function isNumeric(value) {
    return /^\d+$/.test(value);
  };

  const enableDepositHandler = (x) => {
    setEnableDepositText(x.name);
    setEnableDepositValue(x.id);
  };

  const enableWithdrawHandler = (x) => {
    setEnableWithdrawText(x.name);
    setEnableWithdrawValue(x.id);
  };

  return (
    <Card
      className={cn(styles.card, className)}
      classTitle="title-green"
    >
      {loading && <LoaderScreen />}
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Coin Name"
            name="title"
            type="text"
            value={coinName}
            onChange={(e) => { setCoinName(e.target.value) }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Coin Symbol"
            placeholder="Please enter a value"
            defaultValue={state.symbol}
            required
            disabled={true}
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Max Fee"
            name="title"
            placeholder="Please enter a value"
            type="text"
            value={coinMaxFee}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setCoinMaxFee(e.target.value)
              }
              //  setCoinMaxFee(e.target.value)
            }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Min Fee"
            name="title"
            type="text"
            placeholder="Please enter a value"
            value={coinMinFee}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setCoinMinFee(e.target.value)
              }
              // setCoinMinFee(e.target.value) 
            }}
            required
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Min Balance"
            name="minBalance"
            type="text"
            placeholder="Please enter a value"
            value={coinMinBalance}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setCoinMinBalance(e.target.value)
              }
              // setCoinMinBalance(e.target.value)
            }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Decimal Precision"
            name="title"
            type="text"
            placeholder="Please enter a value"
            value={decimalPrecision}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setDecimalPrecision(e.target.value)
              }
            }}
            required
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Margin Spread Value"
            name="marginSpreadValue"
            type="text"
            placeholder="Please enter a value"
            value={spreadValue}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setSpreadValue(e.target.value)
              }
            }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Convert Convert Value"
            name="title"
            type="text"
            placeholder="Please enter a value"
            value={convertSpreadValue}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setConvertSpreadValue(e.target.value)
              }
            }}
            required
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <CustomDropdown
            className={styles.field}
            label="Enable Deposit"
            options={enableDepositOptions}
            text={enableDepositText}
            setText={setEnableDepositText}
            handleClick={enableDepositHandler}
          />
        </div>
        <div className={styles.customField}>
          <CustomDropdown
            className={styles.field}
            label="Enable Withdraw"
            options={enableWithdrawOptions}
            text={enableWithdrawText}
            setText={setEnableWithdrawText}
            handleClick={enableWithdrawHandler}
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <Dropdown
            className={styles.field}
            label="Maintenance Mode"
            value={maintenanceMode}
            setValue={setMaintenanceMode}
            options={maintenanceModeOption}
          />
        </div>
        <div className={styles.customField}>
          {maintenanceMode.toLowerCase() === "no" &&
            <Dropdown
              className={styles.field}
              label="Status"
              value={status}
              setValue={setStatus}
              options={statusOptions}
            />
          }
        </div>
        <div className={styles.description}>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Icon"
              name="icon"
              type="file"
              onChange={(e) => { setCoinImage(e.target.files[0]) }}
            />
          </div>
          <div className={styles.customField}>
            <TextInput
              className={styles.field}
              label="Banner"
              name="banner"
              type="file"
              onChange={(e) => { setBannerImage(e.target.files[0]) }}
            />
          </div>
        </div>
        {
          selected?.length > 0 ?
            <>
              {setLoading(false)}
              <p>Select Network</p>
              <Select
                placeholder="Please select a value"
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={coinsNetwork}
                defaultValue={selected}
                onChange={handleChange}
              />
            </>
            :
            <>
              <p className={styles.desc}>Select Network</p>
              <Select
                placeholder="Please select a value"
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={coinsNetwork}
                onChange={handleChange}
              />
            </>
        }
        <div className={styles.desc}>
          <p htmlFor="textArea">Coin Description</p>
          <textarea
            label="Description"
            className={styles.textArea}
            placeholder="Please enter the coin description"
            rows={8}
            value={coinDescription}
            onChange={(e) => setCoinDescription(e.target.value)}
          />
        </div>
        <div className={styles.description}>
          {
            fields.map((field, index) => (
              <div key={index}>
                <div className={styles.customField}>
                  <TextInput
                    className={styles.field}
                    label={`${index + 1}. Title`}
                    name="title"
                    type="text"
                    placeholder="Please enter a value"
                    value={field.title}
                    onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                    required
                  />
                </div>
                <textarea
                  label="Description"
                  className={styles.textArea}
                  placeholder="Enter the description here"
                  rows={8}
                  value={field.description}
                  onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                />
                {fields?.length > 1 &&
                  <button className={styles.styledbutton} onClick={() => removeField(index)}>Remove</button>
                }
              </div>
            ))}
          <button className={styles.styledbutton} onClick={addField}>Add Fields</button>
        </div>

        {/* {
          (state?.stableCoin === parseInt(process.env.REACT_APP_UNSTABLE_COIN)) && (
            selected?.length > 0 ?
              <>
                {setLoading(false)}
                <p>Select Network</p>
                <Select
                  placeholder="Please select a value"
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={coinsNetwork}
                  defaultValue={selected}
                  onChange={handleChange}
                />
              </>
              :
              setLoading(true)
          )
        } */}
        <br />
        <button
          onClick={(e) => {
            e.preventDefault();
            if (coinName !== "" && coinMaxFee !== "" && coinMinFee !== "" && coinMinBalance !== "" && decimalPrecision !== "" && spreadValue != "" && convertSpreadValue != "") {
              if (isNumeric(decimalPrecision)) {
                updateCoinHandler(status, maintenanceMode, decimalPrecision,
                  coinName, coinMaxFee, coinMinFee, coinMinBalance, enableDepositValue, enableWithdrawValue, state.symbol, coinImage ? coinImage : state?.icon, bannerImage ? bannerImage : state?.banner, coinDescription, spreadValue, convertSpreadValue);
              }
              else {
                addNotification({
                  title: 'Error',
                  message: "Only numeric values are allowed in decimal price precision",
                  type: 'danger'
                });
              }
            }
            else {
              addNotification({
                title: 'Error',
                message: "All fields are mandatory",
                type: 'danger'
              });
            }
          }
          }
          className={cn("button", styles.button)}>
          Save Changes
        </button>
      </div>
    </Card>
  );
};

export default NameAndDescription;
