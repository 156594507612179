import React, { useEffect, useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRegionCreator } from "../../../../actions/getRegions";
import Checkbox from "../../../../components/Checkbox";

const Row = ({ selectedItems, setSelectedItems, item, order, region, status, updateUserHandler, superadmin }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const { getRegionData } = useSelector((state) => state.getRegion);
  const dispatch = useDispatch();
  const [regionText, setRegionText] = useState("");

  // useEffect(() => {
  //   setRegionText("");
  //   if (getRegionData?.length === 0) {
  //     dispatch(getRegionCreator());
  //   }
  //   getRegionData?.filter(x => {
  //     if (x?.id === item?.region) {
  //       setRegionText(x.name);
  //     };
  //   });
  // }, [getRegionData, order]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleChange = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    }
    else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  return (
    <div className={styles.row}>
      {(status === process.env.REACT_APP_KYCVERIFICATIONPENDINGSTRING ||
        status === process.env.REACT_APP_UNVERIFIED_EMAIL_CUSTOMERS_STRING ||
        status === process.env.REACT_APP_REACTIVATE_CUSTOMERS_STRING
      ) &&
        <div className={styles.col}>
          <Checkbox
            onChange={() => handleChange(item.id)}
          />
        </div>}
      <div className={styles.col}>
        {item.id}
      </div>
      <Link className={styles.col}
        to={{
          pathname: `/profile/${item.id}`,
          state: item,
        }}>
        {item.fname === null || undefined || item.lname === null || undefined ? "---" : item.fname + " " + item.lname}
        <div className={styles.text}>
          {item.email}
        </div>
      </Link>

      <div className={styles.col}>
        {item.uid}
      </div>

      <div className={styles.col}>
        {region == parseInt(process.env.REACT_APP_INTERNATIONAL_REGION_VALUE) ? "International" :
          region === parseInt(process.env.REACT_APP_EUROPE_REGION_VALUE) ? "Europe" :
            ""}
      </div>

      {status !== process.env.REACT_APP_KYCVERIFICATIONINPROCESSSTRING &&
        <div className={styles.col}>
          {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONINPROCESS) && (
            <div className={cn("status-red-dark", styles.distribution)}>
              In-process
            </div>
          )}

          {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONPENDING) && (
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
          )}

          {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONCOMPLETE) && (
            <div className={cn("status-green-dark", styles.distribution)}>
              Completed
            </div>
          )}

          {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONFAILURE) && (
            <div className={cn("status-red-dark", styles.distribution)}>
              Failed
            </div>
          )}

          {item?.bank_verification === parseInt(process.env.REACT_APP_BANKVERIFICATIONREJECT) && (
            <div className={cn("status-red-dark", styles.distribution)}>
              Rejected
            </div>
          )}
        </div>}

      {status !== process.env.REACT_APP_KYCVERIFICATIONINPROCESSSTRING &&
        <div className={styles.col}>
          {item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONPENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
            :
            item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONUSEREXIT) ?
              <div className={cn("status-red-dark", styles.distribution)}>
                User Exit
              </div>
              :
              item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONINPROCESS) ?
                <div className={cn("status-red-dark", styles.distribution)}>
                  In-process
                </div>
                :
                item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONONERROR) ?
                  <div className={cn("status-red-dark", styles.distribution)}>
                    Token Expired
                  </div>
                  :
                  item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONCOMPLETE) ?
                    <div className={cn("status-green-dark", styles.distribution)}>
                      Completed
                    </div>
                    :
                    "---"
          }
        </div>}

      {status !== process.env.REACT_APP_KYCVERIFICATIONINPROCESSSTRING &&
        <div className={styles.col}>
          {item?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_PENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
            :
            item?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_INPROCESS) ?
              <div className={cn("status-red-dark", styles.distribution)}>
                In-process
              </div>
              :
              item?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_COMPLETE) ?
                <div className={cn("status-green-dark", styles.distribution)}>
                  Completed
                </div>
                :
                item?.residence_verification === parseInt(process.env.REACT_APP_RESIDENCE_VERIFICATION_REJECT) ?
                  <div className={cn("status-red-dark", styles.distribution)}>
                    Rejected
                  </div>
                  :
                  "---"
          }
        </div>}

      <div className={styles.col}>
        {item.createdAt}
      </div>
      <div className={styles.col}>
        {item.risk_profile === parseInt(process.env.REACT_APP_RISK_PROFILE_LOW) ? <span className={cn("status-green-dark", styles.distribution)}>Low</span> :
          item.risk_profile === parseInt(process.env.REACT_APP_RISK_PROFILE_MEDIUM) ? <span className={cn("status-yellow", styles.distribution)}>Medium</span> :
            item.risk_profile === parseInt(process.env.REACT_APP_RISK_PROFILE_HIGH) ? <span className={cn("status-red-dark", styles.distribution)}>High</span> :
              "---"
        }
      </div>

      {(status == process.env.REACT_APP_KYCVERIFICATIONINPROCESSSTRING && superadmin) &&
        <div className={styles.row}>
          <div className={styles.buttonClass}>
            <div className={styles.settings}>
              <div>
                <button className={cn("button", styles.acceptButton)} type="button"
                  onClick={(e) => { updateUserHandler(item?.id, item?.fname, item?.lname, item?.email_verified, parseInt(process.env.REACT_APP_KYCVERIFICATIONCOMPLETE), item?.residence_verification, item?.region, item?.risk_profile, item?.auth_enable, item?.residence_address, item?.notes) }}
                >
                  <span>Accept</span>
                </button>
                <button className={cn("button", styles.rejectButton)} type="button"
                  onClick={(e) => { updateUserHandler(item?.id, item?.fname, item?.lname, item?.email_verified, parseInt(process.env.REACT_APP_KYCVERIFICATIONPENDING), item?.residence_verification, item?.region, item?.risk_profile, item?.auth_enable, item?.residence_address, item?.notes) }}
                >
                  <span>Reject</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>

  );
};

export default Row;
