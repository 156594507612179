import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import { userDetailsCreator } from "../../actions/userDetails";

const Page = ({ wide, children, title }) => {
  const [visible, setVisible] = useState(false);
  const [isToggled, setToggled] = useState(localStorage.getItem("sideMenu"));
  const dispatch = useDispatch();
  const { usersDetails } = useSelector((state) => state.userDetails);
  let superadmin = Object.values(usersDetails).includes(process.env.REACT_APP_SUPER_ADMIN_ROLE);
  const location = useLocation();

  const isRootPath = location.pathname === '/' || location.pathname === '/bank-list';

  const handleToggle = () => {
    const newToggledValue = !isToggled;
    setToggled(newToggledValue);
    localStorage.setItem("sideMenu", JSON.stringify(newToggledValue));
  };

  useEffect(() => {
    if (Object.entries(usersDetails).length === 0) {
      dispatch(userDetailsCreator());
    }
  }, []);

  return (
    <>
      <div className={styles.page}>
        <Sidebar
          isToggled={isToggled}
          className={cn(styles.sidebar, { [styles.visible]: visible })}
          onClose={() => setVisible(false)}
        />
        <Header
          isRootPath={isRootPath}
          superadmin={superadmin}
          isToggled={isToggled}
          handleToggle={handleToggle}
          onOpen={() => setVisible(true)} />
        <div className={styles.inner}>
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
          >
            {title && <div className={cn("h3", styles.title)}>{title}</div>}
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;
