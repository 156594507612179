import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";

const Promote = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationDetails, setNotificationDetails] = useState([]);

  useEffect(() => {
    getAllNotificationHandler();
  }, []);

  const getAllNotificationHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const getAllNotificationPayload = await requestHandler("getNotifications", "post", data, "jwt_token");
      setLoading(false);
      setNotificationData(getAllNotificationPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.section}>
        {loading && <LoaderScreen />}
        <RecentPost
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          notificationData={notificationData}
          notificationDetails={notificationDetails}
          setNotificationDetails={setNotificationDetails}
        />
      </div>
    </>
  );
};

export default Promote;
