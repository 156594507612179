import {
    USER_DETAILS_FAILED,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS
} from "./actionType";
import requstHandler from "../httpClient";


const userDetailsRequest = () => {
    return {
        type: USER_DETAILS_REQUEST,
    };
};

const userDetailsSuccess = (payload) => {
    return {
        type: USER_DETAILS_SUCCESS,
        payload: payload,
    };
};

const userDetailsFailed = (err) => {
    return {
        type: USER_DETAILS_FAILED,
        message: err,
    };
};

const userDetailsCreator = () => {
    return async (dispatch) => {
        dispatch(userDetailsRequest());
        try {
            let data = {
                signature: localStorage.getItem("signature"),
            };
            const userDetailsPayload = await requstHandler("getAllStats", 'post', data, 'jwt_token');
            dispatch(userDetailsSuccess(userDetailsPayload.data?.data));
        }
        catch (e) {
            dispatch(userDetailsFailed(e.message));
        };
    };
};

export { userDetailsRequest, userDetailsSuccess, userDetailsFailed, userDetailsCreator };