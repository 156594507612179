import React, { useEffect, useState } from "react";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";

const statusOptions = [{ "id": 0, "name": "Open" }, { "id": 1, "name": "Redeemed" }];
const regionOptions = ["Europe", "International", "Suriname"];

const Promote = () => {
  const [loading, setLoading] = useState(false);
  const [allContractData, setAllContractData] = useState([]);
  const [statusText, setStatusText] = useState(statusOptions[0].name);
  const [statusValue, setStatusValue] = useState(statusOptions[0].id);
  const [regionValue, setRegionValue] = useState(regionOptions[0]);

  useEffect(() => {
    getAllContractHandler();
  }, [statusValue, regionValue]);

  const getAllContractHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      status: statusValue,
      region: regionValue?.toUpperCase(),
    };
    try {
      const getAllContractPayload = await requestHandler("get-all-stakecrypto", "post", data, "jwt_token");
      setLoading(false);
      setAllContractData(getAllContractPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const statusHandler = (x) => {
    setStatusValue(x.id);
    setStatusText(x.name);
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          loading={loading}
          allContractData={allContractData}
          statusText={statusText}
          setStatusText={setStatusText}
          statusValue={statusValue}
          setStatusValue={setStatusValue}
          statusOptions={statusOptions}
          statusHandler={statusHandler}
          regionValue={regionValue}
          setRegionValue={setRegionValue}
          regionOptions={regionOptions}
        />
      </div>
    </>
  );
};

export default Promote;
