import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import Dropdown from "../../../../../components/Dropdown";

const tokenOptions = ["ERC20", "TRC20", "BSC", "BNB"];

const Share = ({
  updateAddressHandler,
  item
}) => {
  const [tokenValue, setTokenValue] = useState("");

  useEffect(() => {
    if (item?.network_slug && tokenOptions?.includes(item.network_slug)) {
      setTokenValue(item?.network_slug);
    } else {
      setTokenValue(tokenOptions[0]);
    }
  }, [item, tokenOptions]);

  return (
    <div className={styles.post}>
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>Edit Address</div>
        <div className={styles.description}>
          <div className={styles.customField}>
            <Dropdown
              label="Token"
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={tokenValue}
              setValue={setTokenValue}
              options={tokenOptions}
              small
            />
          </div>
        </div>
        <div className={styles.control}>
          <button className={cn("button", styles.button)} onClick={(e) => {
            e.preventDefault();
            updateAddressHandler(item.userId, item.id, item?.status, tokenValue)
          }
          }>
            <span>Continue</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Share;
