import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import CustomDropdown from "../../../components/CustomDropdown";

const RecentPost = ({
  className,
  loading,
  linkedAccountData,
  updateLinkedAccountHandler,
  statusText,
  setStatusText,
  statusValue,
  setStatusValue,
  statusOptions,
  statusHandler
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Added"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.customField}>
              <CustomDropdown
                className={styles.field}
                options={statusOptions}
                text={statusText}
                setText={setStatusText}
                value={statusValue}
                setValue={setStatusValue}
                handleClick={statusHandler}
              />
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>User Id</div>
            <div className={styles.col}>Linked User Id</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            <div className={styles.col}>Action</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={6} rowCount={12} />
            :
            linkedAccountData?.length > 0 ?
              <>
                {
                  linkedAccountData?.map((x, index) => (
                    <Row
                      item={x}
                      key={index}
                      index={index}
                      updateLinkedAccountHandler={updateLinkedAccountHandler}
                    />
                  ))}
              </>
              :
              "No data found"
          }
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
