import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./Home.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import PopularProducts from "../../components/PopularProducts";
import Comments from "./Comments";
import RefundRequests from "../../components/RefundRequests";
import ProTips from "./ProTips";
import MoreCustomers from "./MoreCustomers";
import ProductViews from "./ProductViews";
import { useSelector, useDispatch } from "react-redux";
import { userDetailsCreator } from "../../actions/userDetails";

const Home = () => {
  const dispatch = useDispatch();
  const { usersDetails } = useSelector((state) => state.userDetails);

  useEffect(() => {
    dispatch(userDetailsCreator());
  }, []);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Overview
            className={styles.card}
            usersDetails={usersDetails}
          />
          {/* <ProductViews className={styles.card} /> */}
          {/* <ProTips className={styles.card} /> */}
          {/* <MoreCustomers /> */}
        </div>
        <div className={styles.col}>
          <PopularProducts
            usersDetails={usersDetails}
            className={styles.card} views="4" />
          {/* <Comments className={styles.card} />
          <RefundRequests title="Refund requests" classTitle="title-red" /> */}
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Home;
