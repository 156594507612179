import React from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import { toLocaleStringDateFormat } from "../../../../../components/helper";

const Share = ({
  notificationDetails
}) => {

  return (
    <>
      <div className={styles.share}>
        <div className={styles.head}>
          <div className={cn("title-red", styles.title)}>Notification Details</div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>Title</div>
          <div className={styles.col}>
            {notificationDetails?.title}
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>
            Message
          </div>
          <div className={styles.col}>
            {notificationDetails?.body}
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>Navigation</div>
          <div className={styles.col}>{notificationDetails?.navigate}</div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>Type</div>
          <div className={styles.col}>{notificationDetails?.type}</div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>Region</div>
          <div className={styles.col}>{
            notificationDetails?.region?.length === 0 ?
              "All"
              :
              notificationDetails?.region?.map((x) => {
                return <div className={styles.col}>{x}</div>;
              })
          }
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>Status</div>
          <div className={cn("status-green-dark", styles.distribution)}>{notificationDetails?.status?.toUpperCase()}</div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>Customer Type</div>
          <div className={styles.col}>{notificationDetails?.userType}</div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>Customer Status(Based on KYC)</div>
          <div className={styles.col}>{
            notificationDetails?.userId?.length < 0 ?
              notificationDetails?.kycStatus?.length === 0 ? "All Customers" :
                notificationDetails?.kycStatus?.map((x) => {
                  return <div className={styles.col}>{x}</div>;
                })
              :
              "N/A"
          }</div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>User Id</div>
          <div className={styles.col}>
            {
              notificationDetails?.userId === null || notificationDetails?.userId === undefined || notificationDetails?.userId === "" ? "N/A" :
                notificationDetails?.userId?.map((x) => {
                  return <div className={styles.col}>{x}</div>;
                })
            }
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>Scheduled Time</div>
          {toLocaleStringDateFormat(notificationDetails?.scheduledTime)}
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>Date</div>
          <div className={styles.col}>
            {toLocaleStringDateFormat(notificationDetails.createdAt)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Share;
