import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import CustomDropdown from "../../../components/CustomDropdown";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Dropdown from "../../../components/Dropdown";

const RecentPost = ({
  className,
  loading,
  allContractData,
  statusText,
  setStatusText,
  statusValue,
  setStatusValue,
  statusOptions,
  statusHandler,
  regionValue,
  setRegionValue,
  regionOptions
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Contract"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.customField}>
              <Dropdown
                className={styles.field}
                value={regionValue}
                setValue={setRegionValue}
                options={regionOptions}
              />
            </div>
            <div className={styles.customField}>
              <CustomDropdown
                className={styles.field}
                options={statusOptions}
                text={statusText}
                setText={setStatusText}
                value={statusValue}
                setValue={setStatusValue}
                handleClick={statusHandler}
              />
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Coin</div>
            <div className={styles.col}>Contract Id</div>
            <div className={styles.col}>Customer Id</div>
            <div className={styles.col}>Staked Amount</div>
            <div className={styles.col}>Region</div>
            <div className={styles.col}>APY</div>
            <div className={styles.col}>Staked Days</div>
            <div className={styles.col}>Date</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={8} rowCount={12} />
            :
            allContractData?.length > 0 ?
              <>
                {allContractData?.map((x, index) => (
                  <Row
                    item={x}
                    key={index}
                  />
                ))}
              </>
              :
              "No data found"
          }
        </div>
      </Card>
    </>
  );
};


export default RecentPost;
