import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({
  item,
  index,
  updateLinkedAccountHandler
}) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {index + 1}
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.userId}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.linked_userId}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        {parseInt(item?.status) === parseInt(process.env.REACT_APP_LINKED_ACCOUNT_INPROCESS) ?
          (<div className={cn("status-red-dark", styles.distribution)}>In-process</div>)
          :
          parseInt(item?.status) === parseInt(process.env.REACT_APP_LINKED_ACCOUNT_ACTIVE) ?
            (<div className={cn("status-green-dark", styles.distribution)}>Active</div>)
            :
            (<div className={cn("status-red-dark", styles.distribution)}>Declined</div>)
        }
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.createdAt)}
      </div>
      {
        parseInt(item?.status) === parseInt(process.env.REACT_APP_LINKED_ACCOUNT_INPROCESS) ?
          <div className={styles.row}>
            <div className={styles.col}>
              <button className={cn("button", styles.acceptButton)} type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  updateLinkedAccountHandler(item?.id, parseInt(process.env.REACT_APP_LINKED_ACCOUNT_ACTIVE));
                }}
              >
                <span>Accept</span>
              </button>
              <button className={cn("button", styles.rejectButton)} type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  updateLinkedAccountHandler(item?.id, parseInt(process.env.REACT_APP_LINKED_ACCOUNT_DECLINE));
                }}
              >
                <span>Reject</span>
              </button>
            </div>
          </div>
          :
          <div className={styles.col}>
            ---
          </div>
      }
    </div>
  );
};

export default Row;
