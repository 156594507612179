import React, { useState } from "react";
import cn from "classnames";
import { Link, useNavigate } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import CustomDropdown from "../../../components/CustomDropdown";
import LoaderScreen from "../../../components/LoaderScreen";
import { useEffect } from "react";
import requestHandler from "../../../actions/httpClient";
import { addNotification } from "../../../components/Notification";
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { getRegionCreator } from "../../../actions/getRegions";

const marketOption = ["Yes", "No"];
const statusOption = ["Enable", "Disable"];
const marginTradingOptions = ["Enable", "Disable"];

const NameAndDescription = ({ className }) => {
  const [marketOrderValue, setMarketOrderValue] = useState(marketOption[0]);
  const [statusValue, setStatusValue] = useState(statusOption[0]);
  const [marketPrice, setMarketPrice] = useState();
  const [currentMarketPrice, setCurrentMarketPrice] = useState();
  const [buyPrice, setBuyPrice] = useState();
  const [sellPrice, setSellPrice] = useState();
  const [decimalPrecision, setDecimalPrecision] = useState("");
  const [dayChange, setDayChange] = useState();
  const [weekChange, setWeekChange] = useState();
  const [cap, setCap] = useState("");
  const [volume, setVolume] = useState();
  const [coinName, setCoinName] = useState("Select a value");
  const [currencySymbol, setCurrencySymbol] = useState();
  const [slug, setSlug] = useState("");
  const [currencyName, setCurrencyName] = useState("Select a value");
  const [amountDecimalPrecision, setAmountDecimalPrecision] = useState("");
  const [loading, setLoading] = useState(false);;
  const [coinId, setCoinId] = useState();
  const [currencyId, setCurrencyId] = useState();
  const [coinsOptions, setCoinsOptions] = useState([]);
  const [coinSymbol, setCoinSymbol] = useState();
  const [skills, setSkills] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [regionValue, setRegionValue] = useState([]);
  const [botAvgBalanceFrom, setBotAvgBalanceFrom] = useState("");
  const [botAvgBalanceTo, setBotAvgBalanceTo] = useState("");
  const [botCallInterval, setBotCallInterval] = useState("");
  const [botAvgCryptoFrom, setBotAvgCryptoFrom] = useState("");
  const [botAvgCryptoTo, setBotAvgCryptoTo] = useState("");
  const [botSpread, setBotSpread] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const { getRegionData } = useSelector((state) => state.getRegion);
  const [marginTrading, setMarginTrading] = useState(marginTradingOptions[0]);
  const [maxLeverageTrading, setMaxLeverageTrading] = useState([]);
  const [marginLeverageSpread, setMarginLeverageSpread] = useState("");
  const [futurePriceDiff, setFuturePriceDiff] = useState("");
  const [parsedArray, setParsedArray] = useState([]);

  const newArr = getRegionData?.map((x) => {
    return { "value": x.id, "label": x.name }
  });

  const handleChange = (skills) => {
    setSkills(skills);
    const arr = skills?.map((x) => {
      return x.value;
    });
    setRegionValue([...arr]);
  };

  useEffect(() => {
    getCoins();

    if (getRegionData?.length === 0) {
      dispatch(getRegionCreator());
    };

  }, []);

  const getCoins = async () => {
    setLoading(true);
    try {
      const coinsPayload = await requestHandler("getCoins", "post", signatureData, "jwt_token");
      setLoading(false);
      setCoinsOptions(coinsPayload?.data?.data)

      let dummyArr = coinsPayload?.data?.data?.filter((x) => {
        if (x.symbol.toLowerCase() === "eur" || x.symbol.toLowerCase() === "usdt" || x.symbol.toLowerCase() === "srd") {
          return x;
        }
      });
      setCurrencyOptions(dummyArr);
    }
    catch (e) {
      setLoading(false);
    };
  };

  let signatureData = {
    signature: localStorage.getItem("signature"),
  };

  const addNewMarketHandler = async (coinId, coinSymbol, currencyId, currencySymbol, slug, marketPrice, currentMarketPrice, buyPrice, sellPrice, decimalPrecision, dayChange, weekChange, cap, volume, amountDecimalPrecision, maxLeverageTrading, marginLeverageSpread,
    //  futurePriceDiff
  ) => {
    // setLoading(true);
    const parsedMaxLeverageTrading = parseInputToArray(maxLeverageTrading); // Get parsed array


    let data = {
      coinId: coinId,
      currencyId: currencyId,
      disableMarketOrder: marketOrderValue.toLowerCase() === "yes" ? 1 : 0,
      status: statusValue.toLowerCase() === "enable" ? 1 : 0,
      marketPrice: parseFloat(marketPrice),
      currentMarketPrice: parseFloat(currentMarketPrice),
      slug: `${coinSymbol?.toUpperCase()}-${currencySymbol?.toUpperCase()}`,
      buyPrice: parseFloat(buyPrice),
      sellPrice: parseFloat(sellPrice),
      priceDecimalPrecision: parseInt(decimalPrecision),
      dayChange: parseFloat(dayChange),
      weekChange: parseFloat(weekChange),
      cap: parseFloat(cap),
      volume: parseFloat(volume),
      coin: coinSymbol,
      currency: currencySymbol,
      amountDecimalPrecision: parseInt(amountDecimalPrecision),
      signature: localStorage.getItem("signature"),
      region: regionValue ? JSON.stringify(regionValue) : null,
      bot_avg_bal_from: botAvgBalanceFrom,
      bot_avg_bal_to: botAvgBalanceTo,
      bot_call_interval: botCallInterval,
      bot_avg_crypto_from: botAvgCryptoFrom,
      bot_avg_crypto_to: botAvgCryptoTo,
      // bot_spread: JSON.stringify(finalBotSpread),
      maxLeverageTrading: JSON.stringify(parsedMaxLeverageTrading),
      margin_leverage_spread: marginLeverageSpread,
      // future_price_diff: futurePriceDiff,
      isMarginTrading: marginTrading?.toLowerCase() === "enable" ? 1 : 0,
    };
    // console.log("data", data);
    try {
      const addNewMarketPayload = await requestHandler("new_market", "post", data, "jwt_token");
      // console.log("addNewMarketPayload", addNewMarketPayload);
      setLoading(false);
      if (addNewMarketPayload && addNewMarketPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: addNewMarketPayload?.data?.message[0]?.msg,
          type: 'success'
        });
        navigate("/trade/market");
      }
    }
    catch (e) {
      // console.log("error", e);
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    }
  };

  const handleName = (x) => {
    setCoinId(x.id);
    setCoinSymbol(x.symbol);
    setCoinName(x.name);
  };

  const handleCurrency = (x) => {
    setCurrencyId(x.id);
    setCurrencySymbol(x.symbol);
    setCurrencyName(x.name);
  };

  const parseInputToArray = (maxLeverageTrading) => {
    const numberArray = maxLeverageTrading
      .split(",") // Split by commas
      .map((num) => num.trim()) // Remove extra spaces
      .filter((num) => !isNaN(num) && num !== "") // Remove invalid entries
      .map(Number); // Convert to numbers

    // console.log("Data to send:", numberArray); // Log parsed array
    setParsedArray(numberArray); // Update parsed array state
    return numberArray; // Return the parsed array
  };

  return (
    <Card
      className={cn(styles.card, className)}
      classTitle="title-green"
      head={
        <Link
          className={cn("button-stroke button-small", styles.button)}
          to="/trade/market/add-market"
        >
          <Icon name="arrow-left" size="24" />
          <span>Back</span>
        </Link>
      }>
      {loading && <LoaderScreen />}
      <div className={styles.description}>
        <div className={styles.customField}>
          <CustomDropdown
            className={styles.field}
            label="Select Coin"
            options={coinsOptions}
            text={coinName}
            handleClick={handleName}
            setText={setCoinName}
            toUpperCase
          />
        </div>
        <div className={styles.customField}>
          <CustomDropdown
            className={styles.field}
            label="Select Currency"
            options={currencyOptions}
            text={currencyName}
            handleClick={handleCurrency}
            setText={setCurrencyName}
            toUpperCase
          />
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <Dropdown
            className={styles.field}
            label="Disable Market Order"
            value={marketOrderValue}
            setValue={setMarketOrderValue}
            options={marketOption}
          />
        </div>
        <div className={styles.customField}>
          <Dropdown
            className={styles.field}
            label="Status"
            value={statusValue}
            setValue={setStatusValue}
            options={statusOption}
          />
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Slug"
            name="slug"
            value={coinSymbol && currencySymbol ? `${coinSymbol?.toUpperCase()}-${currencySymbol?.toUpperCase()}` : ""}
            disabled={true}
            onChange={(e) => { setSlug(e.target.value) }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Market Price"
            name="marketPrice"
            placeholder="Please enter a value"
            value={marketPrice}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setMarketPrice(e.target.value)
              }
            }}
            required
          />
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Current Market Price"
            name="CurrentMarketPrice"
            type="text"
            placeholder="Please enter a value"
            value={currentMarketPrice}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setCurrentMarketPrice(e.target.value)
              }
            }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Buy Price"
            name="buyPrice"
            type="text"
            placeholder="Please enter a value"
            value={buyPrice}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBuyPrice(e.target.value)
              }
            }}
            required
          />
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Sell Price"
            name="sellPrice"
            type="text"
            placeholder="Please enter a value"
            value={sellPrice}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setSellPrice(e.target.value)
              }
            }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Price Decimal Precision"
            name="decimalPrecision"
            type="text"
            placeholder="Please enter a value"
            value={decimalPrecision}
            onChange={(e) => {
              // const re = /^\d*\.?\d*$/;
              const re = /^\d+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setDecimalPrecision(e.target.value)
              }
            }}
            required
          />
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Amount Decimal Precision"
            name="amountDecimalPrecision"
            type="text"
            placeholder="Please enter a value"
            value={amountDecimalPrecision}
            onChange={(e) => {
              const re = /^\d+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setAmountDecimalPrecision(e.target.value)
              }
            }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Day Change"
            name="dayChange"
            type="text"
            placeholder="Please enter a value"
            value={dayChange}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setDayChange(e.target.value)
              }
            }}
            required
          />
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Week Change"
            name="weekChange"
            type="text"
            placeholder="Please enter a value"
            value={weekChange}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setWeekChange(e.target.value)
              }
            }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Cap"
            name="cap"
            type="text"
            placeholder="Please enter a value"
            value={cap}
            onChange={(e) => {
              const re = /^\d+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setCap(e.target.value)
              }
            }}
            required
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Volume"
            name="volume"
            type="text"
            placeholder="Please enter a value"
            value={volume}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setVolume(e.target.value)
              }
            }}
            required
          />
        </div>
        <div className={styles.customField}>
          <span>Select Region</span>
          <Select
            options={newArr}
            onChange={handleChange}
            value={skills}
            isMulti
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <Dropdown
            className={styles.field}
            label="Margin Trading"
            value={marginTrading}
            setValue={setMarginTrading}
            options={marginTradingOptions}
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Max Leverage Trading"
            name="maxLeverageTrading"
            type="text"
            placeholder="Please enter a value"
            value={maxLeverageTrading}
            onChange={(e) => setMaxLeverageTrading(e.target.value)}
            // onChange={(e) => {
            //   const re = /^\d+(\.\d+)?$/;
            //   // if (e.target.value === '' || re.test(e.target.value)) {
            //   setMaxLeverageTrading(e.target.value)
            //   // }
            // }}
            required
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Margin Leverage Spread"
            name="marginLevelSpread"
            type="text"
            placeholder="Please enter a value"
            value={marginLeverageSpread}
            onChange={(e) => {
              const re = /^\d+(\.\d+)?$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setMarginLeverageSpread(e.target.value)
              }
            }}
            required
          />
        </div>
        {/* <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Future Price Difference"
            name="futurePriceDiff"
            type="text"
            placeholder="Please enter a value"
            value={futurePriceDiff}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;;
              if (e.target.value === '' || re.test(e.target.value)) {
                setFuturePriceDiff(e.target.value)
              }
            }}
            required
          />
        </div> */}
      </div>

      <hr />
      <br />

      <h4 className={styles.description}>Bot Settings Starts from here</h4>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bot Average Balance(From)"
            name="boatAvgBalanceFrom"
            type="text"
            placeholder="Please enter a value"
            value={botAvgBalanceFrom}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBotAvgBalanceFrom(e.target.value)
              }
            }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bot Average Balance(To)"
            name="cap"
            type="text"
            placeholder="Please enter a value"
            value={botAvgBalanceTo}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBotAvgBalanceTo(e.target.value)
              }
            }}
            required
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bot Call Interval"
            name="botCallInterval"
            type="text"
            placeholder="Please enter a value"
            value={botCallInterval}
            onChange={(e) => {
              const re = /^\d+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBotCallInterval(e.target.value)
              }
            }}
            required
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bot Average Crypto(From)"
            name="botAvgCryptoFrom"
            type="text"
            placeholder="Please enter a value"
            value={botAvgCryptoFrom}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBotAvgCryptoFrom(e.target.value)
              }
            }}
            required
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bot Average Crypto(To)"
            name="botAvgCryptoTo"
            type="text"
            placeholder="Please enter a value"
            value={botAvgCryptoTo}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBotAvgCryptoTo(e.target.value)
              }
            }}
            required
          />
        </div>
        {/* <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bot Spread"
            name="botSpread"
            type="text"
            placeholder="Please enter a value"
            value={botSpread}
            onChange={(e) => {
              const re = /^[0-9.,]+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBotSpread(e.target.value)
              }
            }}
            required
          />
        </div> */}
      </div>
      <br />
      <div className={styles.btns}>
        <button className={cn("button", styles.button)}
          onClick={(e) => {
            e.preventDefault();
            if (coinName.toLowerCase() !== "select a value" && currencyName.toLowerCase() !== "select a value" &&
              marketPrice >= 0 && currentMarketPrice >= 0 && buyPrice >= 0 && sellPrice >= 0 && decimalPrecision >= 0 &&
              dayChange >= 0 && weekChange >= 0 && cap >= 0 && volume >= 0 && amountDecimalPrecision >= 0 && botAvgBalanceFrom !== ""
              && botAvgBalanceTo !== "" && botCallInterval !== "" && botAvgCryptoFrom !== "" && botAvgCryptoTo !== ""
              && maxLeverageTrading !== "" && marginLeverageSpread !== ""
              // && futurePriceDiff !== ""
            ) {
              if (coinName.toLowerCase() !== currencyName.toLowerCase()) {
                if (decimalPrecision.includes(".")) {
                  addNotification({
                    title: 'Error',
                    message: "Only numeric values are allowed, you can't add decimal values in Decimal precision and Cap.",
                    type: 'danger'
                  });
                }
                else {
                  // const newArr = botSpread.split(",");
                  // let filteredData = newArr?.filter((x) => {
                  //   if (x !== "" || x !== " ") {
                  //     return x;
                  //   }
                  // });
                  addNewMarketHandler(coinId, coinSymbol, currencyId, currencySymbol, slug, marketPrice, currentMarketPrice, buyPrice, sellPrice, decimalPrecision, dayChange, weekChange, cap, volume, amountDecimalPrecision, maxLeverageTrading, marginLeverageSpread,
                    //  futurePriceDiff
                    // filteredData
                  );
                }
              }
              else {
                addNotification({
                  title: 'Error',
                  message: "Coin name and Currency name should not be same.",
                  type: 'danger'
                });
              }
            } else {
              addNotification({
                title: 'Error',
                message: "Please fill all the fields and make sure the entered data is valid.",
                type: 'danger'
              });
            }
          }}
        >Save Changes</button>
      </div>
    </Card >
  );
};

export default NameAndDescription;
