import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Icon from "../../../../components/Icon";

const Item = ({ className, onActive, item }) => {

  return (
    <div className={cn(styles.item, className)} onClick={onActive}>
      <div className={styles.icon} style={{ backgroundColor: item.color }}>
        <Icon name={item.icon} size="24" />
      </div>
      {item.hasOwnProperty('online_users') && (
        <div className={styles.details}>
          <div className={styles.subtitle}>
            Online Users
          </div>
          <div className={styles.counter}>{item.online_users}</div>
        </div>
      )}
      {item.hasOwnProperty('total_users') && (
        <div className={styles.details}>
          <div className={styles.subtitle}>
            All Users
          </div>
          <div className={styles.counter}>{item.total_users}</div>
        </div>
      )}
    </div>
  );
};

export default Item;
