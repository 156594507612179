import React from "react";
import cn from "classnames";
import styles from "./Refunds.module.sass";
import Card from "../../../components/Card";
import Row from "./Row";

const Refunds = ({ orderDetails, handlePageClick, pageNo, handleMarketOrderClick, active }) => {
  return (
    <Card
      className={styles.card}
      classCardHead={styles.head}
      title="Orders Information"
      classTitle={cn("title-purple", styles.title)}
    >
      <div className={styles.products}>
        {active ?
          <>
            <button
              className={cn("button-stroke button-small", styles.button)}
              onClick={(e) => {
                if (active === true) {
                  handleMarketOrderClick();
                }
              }}
            >
              Market Orders
            </button>
            <button
              className={cn("button button-small", styles.button)}
              onClick={(e) => {
                if (active === false) {
                  handleMarketOrderClick();
                }
              }}
            >
              Limit Orders
            </button>
          </>
          :
          <>
            <button
              className={cn("button button-small", styles.button)}
              onClick={(e) => {
                if (active === true) {
                  handleMarketOrderClick();
                }
              }}
            >
              Market Orders
            </button>
            <button
              className={cn("button-stroke button-small", styles.button)}
              onClick={(e) => {
                if (active === false) {
                  handleMarketOrderClick();
                }
              }}
            >
              Limit Orders
            </button>
          </>
        }
      </div>
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Order Id</div>
            <div className={styles.col}>Market</div>
            <div className={styles.col}>Type</div>
            <div className={styles.col}>Price</div>
            <div className={styles.col}>Crypto Amount</div>
            <div className={styles.col}>Fiat Amount</div>
            <div className={styles.col}>Total Spend(Currency)</div>
            <div className={styles.col}>Date</div>
          </div>
          {
            orderDetails?.length > 0 ?
              <>
                {orderDetails?.map((x, index) => (
                  <Row item={x} key={index} orderDetails={orderDetails} />
                ))}
                {
                  orderDetails?.length === (10 * pageNo) && <div className={styles.customButton}>
                    <button className={cn("button-stroke button-small", styles.button)} onClick={() => { handlePageClick() }}>
                      <span>Load more</span>
                    </button>
                  </div>
                }
              </>
              :
              "No data found"
          }
        </div>
      </div>
    </Card>
  );
};

export default Refunds;
