import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";

const Promote = () => {
  const [loading, setLoading] = useState(false);
  const [blockedIpData, setBlockedIpData] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);

  useEffect(() => {
    getBlockedIpListHandler();
  }, []);

  const getBlockedIpListHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const getBlockedListPayload = await requestHandler("getblockIp", "post", data, "jwt_token");
      setBlockedIpData(getBlockedListPayload?.data?.data);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const addBlockIpHandler = async (address) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      ip_address: address
    };
    try {
      const addBlockIpPayload = await requestHandler("blockIp", "post", data, "jwt_token");
      setLoading(false);
      if (addBlockIpPayload?.status === 200) {
        addNotification({
          title: "Success",
          message: addBlockIpPayload?.data?.message[0]?.msg,
          type: "success",
        });
        setVisibleModal(false);
        await getBlockedIpListHandler();
      }
    }
    catch (e) {
      setLoading(false);
    };
  };

  const updateBlockedIpHandler = async (id) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      id: id,
    };
    try {
      const updateBlockedIpPayload = await requestHandler("removeblockIp", "post", data, "jwt_token");
      setLoading(false);
      if (updateBlockedIpPayload?.status === 200) {
        addNotification({
          title: "Success",
          message: updateBlockedIpPayload?.data?.message[0]?.msg,
          type: "success",
        });
        await getBlockedIpListHandler();
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "danger",
      });
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          loading={loading}
          blockedIpData={blockedIpData}
          updateBlockedIpHandler={updateBlockedIpHandler}
          addBlockIpHandler={addBlockIpHandler}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
        />
      </div>
    </>
  );
};

export default Promote;
