import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Details from "./Details";
import EditAddress from "./EditAddress";
import NewModal from "../../../../components/NewModal";
import Modal from "../../../../components/Modal";

const Row = ({
  item,
  updateAddressHandler,
  generateAmlReportHandler,
  sorting,
}) => {

  const [showModal, setShowModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className={styles.row} onClick={() => { handleOpenModal() }}>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.preview}>
              <img srcSet={`${item.icon} 2x`} src={item.icon} alt="Coin" />
            </div>
            <div className={styles.details}>
              <div
                className={styles.post}
                dangerouslySetInnerHTML={{ __html: item.name.toUpperCase() }}
              ></div>
              <div className={styles.text}>
                {item.symbol}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            <div className={styles.box}>
              <div className={styles.number}>
                {item?.uid}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{item.address}</div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{item.region}</div>
          </div>
        </div>
        <div className={styles.col}>
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PENDING) && (<div className={cn("status-red-dark", styles.distribution)}>Pending</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PROCESSING) && (<div className={cn("status-red-dark", styles.distribution)}>Processing</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_VERIFIED) && (<div className={cn("status-green-dark", styles.distribution)}>Verified</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_DECLINED) && (<div className={cn("status-red-dark", styles.distribution)}>Declined</div>)}
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_CANCELLED) && (<div className={cn("status-red-dark", styles.distribution)}>Cancelled</div>)}
          {item.status === null || item.status === undefined && "---"}
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{item?.ownership}</div>
          </div>
        </div>
        <div className={styles.col}>
          {
            item?.type === parseInt(process.env.REACT_APP_INTERNAL_CRYPTO_ADDRESS) ? "NA" :
              <div className={styles.item}>
                <div className={styles.preview}>
                  <img className={styles.image} srcSet={`${process.env.REACT_APP_IMAGE_URL + "/" + item.scanOwnership} 2x`} src={process.env.REACT_APP_IMAGE_URL + "/" + item.scanOwnership} alt="proof" />
                </div>
              </div>
          }
        </div>
        <div className={styles.col}>
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PENDING) &&
            item?.isAmlbotverfied === parseInt(process.env.REACT_APP_AML_BOAT_VERIFIED) &&
            <div className={styles.row}>
              <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  <div>
                    <button className={cn("button-small", styles.acceptButton)} type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateAddressHandler(item.userId, item.id, parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_VERIFIED))
                      }}
                    >
                      <span>Accept</span>
                    </button>
                    <button className={cn("button-small", styles.rejectButton)} type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateAddressHandler(item.userId, item.id, parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_DECLINED))
                      }}
                    >
                      <span>Reject</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
          {item.status === parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PENDING) &&
            item?.isAmlbotverfied === parseInt(process.env.REACT_APP_AML_BOAT_UNVERIFIED) &&
            <div className={styles.row}>
              <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  <div>
                    <button className={cn("button-small", styles.acceptButton)} type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        generateAmlReportHandler(item?.id, item?.userId)
                      }}
                    >
                      <span>Generate AML Report</span>
                    </button>
                    <button className={cn("button-small", styles.acceptButton)} type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateAddressHandler(item.userId, item.id, parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_VERIFIED))
                      }}
                    >
                      <span>Accept</span>
                    </button>
                    <button className={cn("button-small", styles.rejectButton)} type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateAddressHandler(item.userId, item.id, parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_DECLINED))
                      }}
                    >
                      <span>Reject</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            item?.type === parseInt(process.env.REACT_APP_INTERNAL_CRYPTO_ADDRESS) ?
              "NA"
              :
              (sorting.toLowerCase() === "verified" || sorting.toLowerCase() === "declined") && <button className={cn("button-small", styles.acceptButton)} type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open( item?.amlReport, "_blank")
                }}
              >
                <span>View Report</span>
              </button>
          }
        </div>
        {sorting && sorting.toLowerCase() === "pending" &&
          <div className={styles.col}>
            <button className={cn("button-small")} type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setVisibleEditModal(true);
              }}
            >
              <span>Edit</span>
            </button>
          </div>
        }
      </div>
      <NewModal
        outerClassName={styles.outer}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
      >
        <Details item={item} />
      </NewModal>
      <Modal
        onClose={() => { setVisibleEditModal(false) }}
        visible={visibleEditModal}
      >
        <EditAddress
          item={item}
          updateAddressHandler={updateAddressHandler}
        />
      </Modal>
    </>
  );
};

export default Row;
