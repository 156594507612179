import React, { useState } from "react";
import cn from "classnames";
import styles from "./PopularProducts.module.sass";
import { Link } from "react-router-dom";
import Card from "../Card";
import ModalProduct from "../ModalProduct";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../Notification";
import { useEffect } from "react";
import { getDigitsAfterDecimal, toFixedAfterTwo } from "../helper";

const PopularProducts = ({ usersDetails, className, views }) => {
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const [loading, setLoading] = useState()
  const [market, setMarket] = useState([])
  const [currency, setCurrency] = useState('')
  let superadmin = Object.values(usersDetails).includes(process.env.REACT_APP_SUPER_ADMIN_ROLE);

  useEffect(() => {
    getMarkets()
  }, [])

  const getMarkets = async () => {
    setLoading(true);
    try {
      let data = {
        type: "adminMarkets",
        region: 1
      };
      const marketsPayload = await requestHandler("get_markets", "post", data);
      setMarket(marketsPayload.data.data[0].markets);
      setCurrency(marketsPayload.data.data[0].currency)
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
    };
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Popular Markets"
        classTitle="title-blue"
      >
        <div className={styles.popular}>
          <div className={styles.head}>
            <div className={styles.stage}>Market ({currency}) </div>
            <div className={styles.stage}>Price</div>
          </div>
          <div className={styles.list}>
            {market.map(
              (x, index) =>
                views > index && (
                  <div
                    className={styles.item}
                    key={index}
                  // onClick={() => setVisibleModalProduct(true)}
                  >
                    <div className={styles.preview}>
                      <img
                        srcSet={`${x.image2x} 2x`}
                        src={x.icon}
                        alt="Product"
                      />
                    </div>
                    <div className={styles.title}>{x.name} ({x.symbol})</div>
                    <div className={styles.details}>
                      {/* <div className={styles.price}>{getDigitsAfterDecimal(x.currentMarketPrice, x.priceDecimalPrecision)} <small> {currency} </small> </div> */}
                      <div className={styles.price}>{getDigitsAfterDecimal(x.currentMarketPrice, x.priceDecimalPrecision)} </div>

                      {parseFloat(x.dayChange) >= 0 ? (
                        <div className={cn("status-green", styles.status)}>
                          {toFixedAfterTwo(x.dayChange)}
                        </div>
                      ) : (
                        <div className={cn("status-red", styles.status)}>
                          {toFixedAfterTwo(x.dayChange)}
                        </div>
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
          <Link
            className={cn("button-stroke", styles.button)}
            to={superadmin ? "/trade/market" : "/"}
          >
            All Markets
          </Link>
        </div>
      </Card>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default PopularProducts;
