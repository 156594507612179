import React, { useEffect, useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Row from "./Row";
import requestHandler from "../../../actions/httpClient";
import Form from "../../../components/Form";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const intervals = [
  "Unverified", "Pending", "Process", "Success", "Cancel", "Declined",
];

const RecentPost = ({ className }) => {
  const dateFilter = process.env.REACT_APP_DATE_FILTER.split(',');
  const [sorting, setSorting] = useState(intervals[3]);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [cryptoDepositList, setCryptoDepositList] = useState([]);
  const [date, setDate] = useState(dateFilter[0]);

  useEffect(() => {
    getCryptoDepositDataHandler();
  }, [pageNo, sorting, date]);

  const handlePageClick = () => {
    setPageNo(pageNo + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const getCryptoDepositDataHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      page: pageNo,
      status: sorting.toLowerCase() === "unverified" ? parseInt(process.env.REACT_APP_DEPOSIT_CRYPTO_TRANSACTION_STATUS_UNVERIFIED) :
        sorting.toLowerCase() === "pending" ? parseInt(process.env.REACT_APP_DEPOSIT_CRYPTO_TRANSACTION_STATUS_PENDING) :
          sorting.toLowerCase() === "process" ? parseInt(process.env.REACT_APP_DEPOSIT_CRYPTO__TRANSACTION_STATUS_PROCESS) :
            sorting.toLowerCase() === "success" ? parseInt(process.env.REACT_APP_DEPOSIT_CRYPTO_TRANSACTION_STATUS_SUCESS) :
              sorting.toLowerCase() === "cancel" ? parseInt(process.env.REACT_APP_DEPOSIT_CRYPTO_TRANSACTION_STATUS_CANCEL) :
                sorting.toLowerCase() === "declined" ? parseInt(process.env.REACT_APP_DEPOSIT_CRYPTO_TRANSACTION_STATUS_DECLINE) :
                  null,
      interval: date.toLowerCase() === "day" ? parseInt(process.env.REACT_APP_DAILY_DATA) :
        date.toLowerCase() === "week" ? parseInt(process.env.REACT_APP_WEEKLY_DATA) :
          date.toLowerCase() === "month" ? parseInt(process.env.REACT_APP_MONTHLY_DATA) :
            date.toLowerCase() === "year" ? parseInt(process.env.REACT_APP_ANNUALY_DATA) : null
    };
    try {
      const cryptoDepositPayload = await requestHandler("allCustomerDepositCrypto", "post", data, "jwt_token");
      setLoading(false);
      setCryptoDepositList(cryptoDepositPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Requests"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by Txn Id"
              type="text"
              name="search"
              icon="search"
            />
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={sorting}
              setValue={setSorting}
              options={intervals}
              small
            />
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={date}
                setValue={setDate}
                options={dateFilter}
                small
              />
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Coin</div>
            <div className={styles.col}>U Id</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Txn Id</div>
            <div className={styles.col}>Main Wallet TxId</div>
            <div className={styles.col}>Date</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={6} rowCount={12} />
            :
            cryptoDepositList.length > 0 ?
              <>
                {
                  cryptoDepositList?.filter((data) => {
                    return data.txid.includes(searchField.toLowerCase())
                  }).filter((y) => {
                    if (y.symbol === process.env.REACT_APP_DEPOSIT_CRYPTO_TRX_SYMBOL) {
                      if (parseFloat(y.amount) > parseFloat(process.env.REACT_APP_DEPOSIT_CRYPTO_TRX_AMOUNT)) {
                        return y;
                      }
                    }
                    else {
                      return y;
                    }
                  })
                    .map((x, index) => (
                      <Row item={x} key={index} />
                    ))}
                {cryptoDepositList?.length === (10 * pageNo) && <div className={styles.foot}>
                  <button className={cn("button-stroke button-small", styles.button)} onClick={() => { handlePageClick() }}>
                    <span>Load more</span>
                  </button>
                </div>
                }
              </>
              :
              "No data found"
          }
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
