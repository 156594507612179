import axios from "axios";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import CryptoJS from 'crypto-js';
import { addNotification } from "../components/Notification";

const BASE_URL = process.env.REACT_APP_API_URL;
const SECRET_KEY = process.env.REACT_APP_AUTHENTICATION_SECRET_KEY;

const getFingerprintID = async () => {
    try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        const visitorId = result.visitorId;

        const hmac = CryptoJS.HmacSHA256(JSON.stringify(visitorId), SECRET_KEY).toString();
        return { fingerPrintId: visitorId, hmac };
    }
    catch (error) {
        return {}
    };
};

const apiCall = async (
    url,
    type,
    formData,
    auth = '',
    isFile = false
) => {
    return new Promise(async (resolve, reject) => {
        let fingerPrintData = await getFingerprintID();

        let headers = {
            'Content-Type': 'application/json',
            //'Content-Type': 'application/x-www-form-urlencoded'
            "fingerprintid": fingerPrintData?.fingerPrintId,
            "hmac": fingerPrintData?.hmac,
        }

        if (isFile) {
            headers = { 'Content-Type': 'multipart/form-data' }
        }
        if (auth === "jwt_token") {
            headers.Authorization = `${localStorage.getItem("jwt_token")}`
        }
        else if (auth.indexOf('JWT') >= 0) {
            headers.Authorization = `${auth}`
        }

        var config = {
            method: type,
            url: `${BASE_URL}/${url}`,
            headers: headers,
        };
        if (formData)
            config.data = formData;


        axios(config)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                if (error.response.status === 403 || error.response.status === 401) {

                    var refresh_token = getCookie('refresh_token')

                    let headers = {
                        'Content-Type': 'application/json',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                        "fingerprintid": fingerPrintData?.fingerPrintId,
                        "hmac": fingerPrintData?.hmac,
                    }
                    headers.Authorization = refresh_token
                    var config_refresh = {
                        method: 'POST',
                        url: `${BASE_URL}/${'refresh_token'}`,
                        headers: headers,
                    };
                    config_refresh.data = formData;
                    return axios(config_refresh)
                        .then(async function (response) {
                            localStorage.setItem("jwt_token", response.data.data[(response.data.data.length) - 1].jwt_token);
                            localStorage.setItem("signature", response.data.data[(response.data.data.length) - 1].signature);
                            var result = await requestHandler(url, type, formData, auth, isFile)
                            resolve(result);
                        })
                        .catch(function (error) {
                            if (error.response.status === 403 || error.response.status === 401) {
                                localStorage.clear()
                                window.location.href = "/sign-in"
                            }
                            reject(error.response);
                        });
                }
                else if (error?.response.status === 429) {
                    addNotification({
                        title: "Alert",
                        message: error?.response?.data?.message[0]?.msg,
                        type: "danger",
                    });
                    reject(error.response);
                }
                else {
                    reject(error.response);
                }
            });
    })

}

const requestHandler = async (
    url,
    type,
    formData,
    auth,
    isFile) => {

    var result = await apiCall(url, type, formData, auth, isFile)
    return result
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export default requestHandler;