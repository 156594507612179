import React, { useEffect, useRef, useState } from "react";
import styles from "./NewProduct.module.sass";
import Form from "./Form";
import LoaderScreen from "../../components/LoaderScreen";
import SimpleReactValidator from "simple-react-validator";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";
import { useNavigate } from "react-router";

const regionValues = [
  { value: "All", label: 'ALL' },
  { value: parseInt(process.env.REACT_APP_EUROPE_REGION_VALUE), label: process.env.REACT_APP_EUROPE_REGION_TEXT?.toUpperCase() },
  { value: parseInt(process.env.REACT_APP_INTERNATIONAL_REGION_VALUE), label: process.env.REACT_APP_DEFAULT_REGION_TEXT?.toUpperCase() },
  { value: parseInt(process.env.REACT_APP_SURINAME_REGION_VALUE), label: process.env.REACT_APP_SURINAME_REGION?.toUpperCase() },
];

const usersTypeValues = [
  "All", "Customer", "Corporate"
];

const customerKycStatusValues = [
  { value: "All Customers", label: "All Customers" },
  { value: parseInt(process.env.REACT_APP_KYCVERIFICATIONPENDING), label: "Pending Customers" },
  { value: parseInt(process.env.REACT_APP_KYCVERIFICATIONINPROCESS), label: "In-Process Customers" },
  { value: parseInt(process.env.REACT_APP_KYCVERIFICATIONCOMPLETE), label: "Completed Customers" },
  { value: parseInt(process.env.REACT_APP_KYCVERIFICATIONINREVIEW), label: "In-Review Customers" },
];

const AddMarket = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [userTypeValue, setUserTypeValue] = useState(usersTypeValues[0]);
  const [notificationTypeValue, setNotificationTypeValue] = useState("Select a value");
  const [notificationTypeValues, setNotificationTypeValues] = useState([]);
  const [navigationTypeValue, setNavigationTypeValue] = useState("Select a value");
  const [navigationTypeValues, setNavigationTypeValues] = useState([]);
  const [regionValue, setRegionValue] = useState(null);
  const [customersValue, setCustomersValue] = useState(null);
  const [userType, setUserType] = useState('individual');
  const [userId, setUserId] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getNotificationTypeHandler();
  }, []);

  const getNotificationTypeHandler = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
    };
    try {
      const notificationTypePayload = await requestHandler("getNotificationTypes", "post", data, "jwt_token");
      setLoading(false);
      setNotificationTypeValues(notificationTypePayload?.data?.data[0]?.type?.notifications);
      setNavigationTypeValues(notificationTypePayload?.data?.data[0]?.navigate?.notifications);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const regionHandler = (selected) => {
    const values = selected ? selected.map((option) => option.value) : [];
    setRegionValue(values);
  };

  const customerHandler = (selected) => {
    const selectedValues = selected?.map(option => option.value);
    setCustomersValue(selectedValues);
  };

  const filteredOptions = (selected) => {
    const selectedValues = selected?.map(option => option.value);
    setRegionValue(selectedValues);
  };

  const addNotificationHandler = async () => {
    let numberUserId = userId[0]?.split(",").map(Number);

    setLoading(true);
    let data = {
      type: notificationTypeValue,
      title: title,
      body: message,
      navigate: navigationTypeValue?.toLowerCase() === "select a value" ? "" : navigationTypeValue,
      userType: userTypeValue?.toLowerCase(),
      region: regionValue?.includes("All") ? [] : regionValue,
      userId: userType?.toLowerCase() === "individual" ? numberUserId : [],
      kycStatus: customersValue?.includes("All Customers") || customersValue === null || customersValue?.length === 0 ? [] : customersValue,
      scheduledTime: fromDate,
      signature: localStorage.getItem("signature"),
    };
    try {
      const addNotificationPayload = await requestHandler("sendNotifications", "post", data, "jwt_token");
      setLoading(false);
      if (addNotificationPayload?.status === 200) {
        addNotification({
          title: "Success",
          message: addNotificationPayload?.data?.message[0].msg,
          type: "success",
        });
        navigate('/notification-management');
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message[0]?.msg,
        type: "danger",
      });
    }
  };

  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue?.toLowerCase() === "individual") {
      setCustomersValue(["All Customers"]);
      simpleValidator.current.showMessageFor("userid");
    } else {
      setUserId([]);
      simpleValidator.current.hideMessageFor("userid");
    }
    setUserType(selectedValue);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.row}>
        <div className={styles.col}>
          <Form
            className={styles.card}
            title={title}
            setTitle={setTitle}
            message={message}
            setMessage={setMessage}
            simpleValidator={simpleValidator}
            forceUpdate={forceUpdate}
            userTypeValue={userTypeValue}
            setUserTypeValue={setUserTypeValue}
            usersTypeValues={usersTypeValues}
            notificationTypeValue={notificationTypeValue}
            setNotificationTypeValue={setNotificationTypeValue}
            notificationTypeValues={notificationTypeValues}
            navigationTypeValue={navigationTypeValue}
            setNavigationTypeValue={setNavigationTypeValue}
            navigationTypeValues={navigationTypeValues}
            regionValues={regionValues}
            regionValue={regionValue}
            setRegionValue={setRegionValue}
            customerKycStatusValues={customerKycStatusValues}
            customersValue={customersValue}
            setCustomersValue={setCustomersValue}
            regionHandler={regionHandler}
            customerHandler={customerHandler}
            filteredOptions={filteredOptions}
            userType={userType}
            setUserType={setUserType}
            userId={userId}
            setUserId={setUserId}
            handleRadioChange={handleRadioChange}
            addNotificationHandler={addNotificationHandler}
            fromDate={fromDate}
            handleFromDateChange={handleFromDateChange}
          />
        </div>
      </div>
    </>
  );
};

export default AddMarket;
