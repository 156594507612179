import React, { useEffect, useState } from "react";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";
import { CSVLink } from 'react-csv'
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { userDetailsCreator } from "../../actions/userDetails";

const searchValues = ["Id", "UId", "Email", "First Name"];
const Promote = ({ status }) => {
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState("DSC");
  const [regionData, setRegionData] = useState([]);
  const [regionText, setRegionText] = useState("");
  const [regionValue, setRegionValue] = useState("");
  const [pageRefreshFlag, setPageRefreshFlag] = useState();
  const [exportedData, setExportedData] = useState([]);
  const { usersDetails } = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [searchField, setSearchField] = useState("");
  const [searchValue, setSearchValue] = useState(searchValues[0]);
  const [dataToExport, setDataToExport] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  let superadmin = Object.values(usersDetails).includes(process.env.REACT_APP_SUPER_ADMIN_ROLE);
  let complianceOfficer = Object.values(usersDetails).includes((process.env.REACT_APP_COMPLIANCE_OFFICER).toLowerCase());
  let rkmManager = Object.values(usersDetails).includes((process.env.REACT_APP_RKM_MANAGER).toLowerCase());

  useEffect(() => {
    if (Object.entries(usersDetails).length === 0) {
      dispatch(userDetailsCreator());
    }
  }, []);

  useEffect(() => {
    const getRegionHandler = async () => {
      setLoading(true);
      try {
        const regionPayload = await requestHandler('region', 'post');
        setLoading(false);
        setRegionData(regionPayload?.data?.data);
        const newDropDownValue = regionPayload?.data?.data?.map(x => {
          return { "id": x.id, "name": x.name }
        });
        newDropDownValue.filter((x) => {
          if (x?.id === parseInt(process.env.REACT_APP_EUROPE_REGION_VALUE)) {
            setRegionValue(x?.id);
            setRegionText(x?.name);
          }
        })
      }
      catch (e) {
        setLoading(false);
      };
    };
    getRegionHandler();
  }, []);

  useEffect(() => {
    if (regionValue !== "") {
      getCustomerDetails();
      exportCustomerDetails();
    }
  }, [regionValue, pageRefreshFlag, pageCount, status]);

  const getCustomerDetails = async () => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        customer_type_status: status,
        region: regionValue ? regionValue : parseInt(process.env.REACT_APP_EUROPE_REGION_VALUE),
        pageno: pageCount,
      };
      const usersPayload = await requestHandler("get_customer_list", "post", data, "jwt_token");
      setLoading(false);
      setTotalPages(usersPayload?.data?.message?.count);
      setUsersData(usersPayload?.data?.message?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const exportCustomerDetails = async () => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        customer_type_status: status,
        region: regionValue ? regionValue : parseInt(process.env.REACT_APP_EUROPE_REGION_VALUE),
      };
      const usersPayload = await requestHandler("get_customer_list_export", "post", data, "jwt_token");
      setLoading(false);
      setAllUsers(usersPayload?.data?.message?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const kycReminderHandler = async () => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        type: process.env.REACT_APP_SEND_EMAIL_TO_ALL
      };
      const kycReminderPayload = await requestHandler("sendKycReminder", "post", data, "jwt_token");
      setLoading(false);
      if (kycReminderPayload && kycReminderPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: kycReminderPayload?.data?.message?.data?.message,
          type: 'success'
        });
      }
    }
    catch (e) {
      setLoading(false);
    }
  };

  const kycReminderSelectedUsersHandler = async (selectedItems) => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
        type: process.env.REACT_APP_SEND_EMAIL_PARTICULAR,
        email_array: selectedItems
      };
      const kycReminderPayload = await requestHandler("sendKycReminder", "post", data, "jwt_token");
      setLoading(false);
      if (kycReminderPayload && kycReminderPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: kycReminderPayload?.data?.message?.data?.message,
          type: 'success'
        });
        setSelectedItems([]);
      }
    }
    catch (e) {
      addNotification({
        title: 'Error',
        message: e?.data?.message[0].msg,
        type: 'danger'
      });
      setLoading(false);
      setSelectedItems([]);
    }
  };

  const emailReminderHandler = async () => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
      };
      const emailReminderPayload = await requestHandler("sendPendingEmailReminder", "post", data, "jwt_token");
      setLoading(false);
      if (emailReminderPayload && emailReminderPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: emailReminderPayload?.data?.message?.data?.message,
          type: 'success'
        });
      }
    }
    catch (e) {
      setLoading(false);
    }
  };

  const reactiveUserEmailReminderHandler = async () => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
      };
      const reactiveUserEmailReminderPayload = await requestHandler("sendReactivateUserReminder", "post", data, "jwt_token");
      setLoading(false);
      if (reactiveUserEmailReminderPayload && reactiveUserEmailReminderPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: reactiveUserEmailReminderPayload?.data?.message?.data?.message,
          type: 'success'
        });
      }
    }
    catch (e) {
      setLoading(false);
    }
  };

  const handleOrder = (e) => {
    const sorted =
      order && order === "ASC"
        ? usersData.sort((a, b) =>
          a["region"] > b["region"] ? 1 : -1
        )
        : usersData.sort((a, b) =>
          a["region"] < b["region"] ? 1 : -1
        );
    const sortingType = order && order === "ASC" ? "DSC" : "ASC";
    setUsersData(sorted);
    setOrder(sortingType);
  };

  const handleRiskOrder = () => {
    const sorted =
      order && order === "ASC"
        ? usersData.sort((a, b) =>
          a["risk_profile"] > b["risk_profile"] ? 1 : -1
        )
        : usersData.sort((a, b) =>
          a["risk_profile"] < b["risk_profile"] ? 1 : -1
        );
    const sortingType = order && order === "ASC" ? "DSC" : "ASC";
    setUsersData(sorted);
    setOrder(sortingType);
  };

  const updateUserHandler = async (userId, fname, lname, email_verified, kyc_verification, residence_verification, region, risk_profile, auth_enable, residence_address, notes) => {
    setLoading(true);
    const data = {
      userId: userId,
      fname: fname,
      lname: lname,
      email_verified: email_verified,
      kyc_verification: kyc_verification,
      residence_verification: residence_verification,
      signature: localStorage.getItem("signature"),
      region: region,
      risk_profile: risk_profile,
      auth_enable: auth_enable,
      residence_address: residence_address,
      notes: notes,
    };
    try {
      const updateUserPayload = await requestHandler("updateCustomer", "post", data, "jwt_token");
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      if (updateUserPayload && updateUserPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: updateUserPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
      }
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
    }
  };

  useEffect(() => {
    customersEmail();
  }, [allUsers]);

  const ExportReactCSV = ({ csvData, fileName }) => {
    return (
      <CSVLink
        data={csvData}
        filename={fileName}>
        <span className={cn("button")}>Export Data</span>
      </CSVLink>
    );
  };

  const customersEmail = () => {
    setLoading(true);
    let newArr = allUsers ? allUsers?.map((x) => {
      return { email: x?.email }
    }) : ''
    setDataToExport([...newArr]);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    setPageCount(page.selected + 1);
  };

  const searchFieldHandler = async (searchField) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      customer_type_status: status,
      region: regionValue ? regionValue : parseInt(process.env.REACT_APP_EUROPE_REGION_VALUE),
      type: searchValue === "First Name" ? "fname" : searchValue.toLowerCase(),
      search_text: searchField,
      pageno: pageCount,
    };
    try {
      if (data.search_text === "") {
        setLoading(false);
        addNotification({
          title: 'Alert',
          message: "Search field can not be empty.",
          type: 'danger'
        });
      } else {
        const searchFieldPayload = await requestHandler("get_customer_list_with_type", "post", data, "jwt_token");
        setLoading(false);
        setTotalPages(searchFieldPayload?.data?.message?.count);
        setUsersData(searchFieldPayload?.data?.message?.data);
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Please verify the entered details.",
        type: "danger",
      });
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          usersData={usersData}
          status={status}
          kycReminderHandler={kycReminderHandler}
          loading={loading}
          setLoading={setLoading}
          emailReminderHandler={emailReminderHandler}
          handleOrder={handleOrder}
          order={order}
          handleRiskOrder={handleRiskOrder}
          regionData={regionData}
          setRegionText={setRegionText}
          regionText={regionText}
          regionValue={regionValue}
          setRegionValue={setRegionValue}
          updateUserHandler={updateUserHandler}
          reactiveUserEmailReminderHandler={reactiveUserEmailReminderHandler}
          exportedData={exportedData}
          setExportedData={setExportedData}
          ExportReactCSV={ExportReactCSV}
          superadmin={superadmin}
          kycReminderSelectedUsersHandler={kycReminderSelectedUsersHandler}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          pageCount={pageCount}
          setPageCount={setPageCount}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          setSearchField={setSearchField}
          searchField={searchField}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchValues={searchValues}
          searchFieldHandler={searchFieldHandler}
          dataToExport={dataToExport}
          complianceOfficer={complianceOfficer}
          rkmManager={rkmManager}
        />
      </div>
    </>
  );
};

export default Promote;
