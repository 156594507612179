import React from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../../../components/helper";

const Share = ({ item }) => {

  return (
    <div className={styles.share}>
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Details</div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Coin</div>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.preview}>
              <img src={`${process.env.REACT_APP_IMAGE_URL}/${item.icon}`} alt="Coin" />
            </div>
            <div className={styles.details}>
              <div
                className={styles.post}
                dangerouslySetInnerHTML={{ __html: item?.coinName?.toUpperCase() }}
              ></div>
              <div className={styles.text}>
                {item?.coinSymbol?.toUpperCase()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          Customer Id
        </div>
        <div className={styles.col}>
          {item?.userId}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Contract Id</div>
        <div className={styles.col}>
          {item?.contract_id === null || item?.contract_id === undefined ? "---" : item?.contract_id}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Staked Amount</div>
        <div className={styles.col}>
          <div className={styles.product}>{getDigitsAfterDecimal(item?.stakeAmount, item?.amountDecimalPrecision)} {item?.coinSymbol?.toUpperCase()}</div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Total Earning</div>
        <div className={styles.col}>
          {getDigitsAfterDecimal(item?.totalStakeProfit, item?.amountDecimalPrecision)} {item?.coinSymbol?.toUpperCase()}
        </div>
      </div>
      {/* <div className={styles.wrapper}>
        <div className={styles.text}>Yesterday Earning</div>
        <div className={styles.col}>
          {getDigitsAfterDecimal(item?.yesterdayProfit, item?.amountDecimalPrecision)} {item?.coinSymbol?.toUpperCase()}
        </div>
      </div> */}
      <div className={styles.wrapper}>
        <div className={styles.text}>Region</div>
        <div className={styles.col}>
          {item?.region}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>APY</div>
        <div className={styles.col}>
          {item?.annualPercentage}%
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Staked Days</div>
        <div className={styles.col}>
          {item?.stakedDays === null || item?.stakedDays === undefined ? "---" : item?.stakedDays}/{item?.totalDays}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Early Redeem Penalty</div>
        <div className={styles.col}>
          {item?.earlyRedeemPenalty}%
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Date</div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(item?.createdAt)}
        </div>
      </div>
    </div>
  );
};

export default Share;
