import React from "react";
import cn from "classnames";
import styles from "./Share.module.sass";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../../../components/helper";

const Share = ({ item }) => {

  return (
    <div className={styles.share}>
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Details</div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Coin</div>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.preview}>
              <img className={styles.img} srcSet={`${item.image2x} 2x`} src={process.env.REACT_APP_IMAGE_URL + "/" + item.icon} alt="Coin" />
            </div>
            <div className={styles.details}>
              <div
                className={styles.post}
                dangerouslySetInnerHTML={{ __html: item.name.toUpperCase() }}
              ></div>
              <div className={styles.text}>
                {item.symbol}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Amount</div>
        <div className={styles.col}>
          {getDigitsAfterDecimal(item.amount, item.decimalPrecision) + " " + item.symbol.toUpperCase()}
        </div>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.text}>
          Customer Id
        </div>
        <div className={styles.col}>
          {item?.uid}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Address</div>
        <div className={styles.col}>
          {item?.address}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Tag/Memo</div>
        <div className={styles.col}>
          {item?.address_tag === undefined || item?.address_tag === null || item?.address_tag === "" ? "---" : item?.address_tag}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Network Slug</div>
        <div className={styles.col}>
          {item?.network_slug ? item?.network_slug : "---"}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Main Wallet TxId</div>
        <div className={styles.col}>
          {item?.mainWalletTxId === null || item?.mainWalletTxId === undefined ? "---" : item?.mainWalletTxId}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Txn Id</div>
        <div className={styles.col}>
          {item?.txid === null || item?.txid === undefined ? "---" : item?.txid}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Status</div>
        <div className={styles.col}>
          {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_UNVERIFIED) && (
            <div className={cn("status-red-dark", styles.distribution)}>Unverified</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_PENDING) && (
            <div className={cn("status-red-dark", styles.distribution)}>Pending</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO__TRANSACTION_STATUS_PROCESS) && (
            <div className={cn("status-red-dark", styles.distribution)}>Process</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_SUCCESS) && (
            <div className={cn("status-green-dark", styles.distribution)}>Success</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_CANCEL) && (
            <div className={cn("status-red-dark", styles.distribution)}>Cancelled</div>
          )}
          {item?.status === parseInt(process.env.REACT_APP_WITHDRAW_CRYPTO_TRANSACTION_STATUS_DECLINE) && (
            <div className={cn("status-red-dark", styles.distribution)}>Declined</div>
          )}
          {item?.status === null || item?.status === undefined && "---"}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Date</div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(item.createdAt)}
        </div>
      </div>
    </div>
  );
};

export default Share;
