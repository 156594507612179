import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({
  item,
  index,
  setNotificationDetails,
  setVisibleModal
}) => {

  return (
    <>
      <div className={styles.row} onClick={() => {
        setVisibleModal(true);
        setNotificationDetails(item);
      }}>
        <div className={styles.col}>
          {index + 1}
        </div>
        <div className={styles.col}>
          {item?.title}
        </div>
        <div className={styles.col}>
          {item?.body}
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            {toLocaleStringDateFormat(item?.scheduledTime)}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            {toLocaleStringDateFormat(item?.createdAt)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Row;
