import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Modal from "../../../components/Modal";
import NewPost from "./NewPost";

const RecentPost = ({
  className,
  loading,
  blockedIpData,
  updateBlockedIpHandler,
  addBlockIpHandler,
  visibleModal,
  setVisibleModal
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Blocked"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <div className={styles.control}>
              <button className={cn("button", styles.dropdown)} onClick={(e) => {
                e.preventDefault();
                setVisibleModal(true);
              }}>
                <span>Add Ip Address</span>
              </button>
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>Ip Address</div>
            <div className={styles.col}>Date</div>
            <div className={styles.col}>Action</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={4} rowCount={12} />
            :
            blockedIpData?.length > 0 ?
              <>
                {
                  blockedIpData?.map((x, index) => (
                    <Row
                      item={x}
                      key={index}
                      index={index}
                      updateBlockedIpHandler={updateBlockedIpHandler}
                    />
                  ))}
              </>
              :
              "No data found"
          }
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost
          loading={loading}
          addBlockIpHandler={addBlockIpHandler}
        />
      </Modal>
    </>
  );
};

export default RecentPost;
