import React, { useEffect, useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat } from "../../../../components/helper";
import { useDispatch, useSelector } from "react-redux";
import { getRegionCreator } from "../../../../actions/getRegions";
import { Link } from "react-router-dom";

const Row = ({ item, updateUserHandler, order, superadmin }) => {

  const { getRegionData } = useSelector((state) => state.getRegion);
  const dispatch = useDispatch();
  const [regionText, setRegionText] = useState("");

  useEffect(() => {
    if (getRegionData?.length === 0) {
      dispatch(getRegionCreator());
    }
    getRegionData?.filter(x => {
      if (x.id === item.region) {
        setRegionText(x.name);
      };
    });
  }, [getRegionData, order]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {item.id}
      </div>
      <Link className={styles.col}
        to={{
          pathname: `/profile/${item.id}`,
          state: item,
        }}>
        {item.fname === null || undefined || item.lname === null || undefined ? "---" : item.fname + " " + item.lname}
        <div className={styles.text}>
          {item.email}
        </div>
      </Link>

      <div className={styles.col}>
        {item.uid}
      </div>
      <div className={styles.col}>
        {capitalizeFirstLetter(regionText.toLowerCase())}
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item.createdAt)}
      </div>
      {superadmin &&
        <div className={styles.col}>
          {item?.kyc_verification === parseInt(process.env.REACT_APP_KYCVERIFICATIONINREVIEW) &&
            <div className={styles.row}>
              <div className={styles.buttonClass}>
                <div className={styles.settings}>
                  <div>
                    <button className={cn("button", styles.acceptButton)} type="button"
                      onClick={(e) => { updateUserHandler(item?.id, item?.fname, item?.lname, item?.email_verified, parseInt(process.env.REACT_APP_KYCVERIFICATIONCOMPLETE), item?.residence_verification, item?.region, item?.risk_profile, item?.auth_enable, item?.mobile_no, item?.country_code, item?.residence_address, item?.notes) }}
                    >
                      <span>Accept</span>
                    </button>
                    <button className={cn("button", styles.rejectButton)} type="button"
                      onClick={(e) => { updateUserHandler(item?.id, item?.fname, item?.lname, item?.email_verified, parseInt(process.env.REACT_APP_KYCVERIFICATIONPENDING), item?.residence_verification, item?.region, item?.risk_profile, item?.auth_enable, item?.mobile_no, item?.country_code, item?.residence_address, item?.notes) }}
                    >
                      <span>Reject</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>}
    </div>
  );
};

export default Row;
