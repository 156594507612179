import React, { useEffect, useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import OrderDropdown from "../../../components/OrderDropdown";
import Row from "./Row";
import requestHandler from "../../../actions/httpClient";
import Form from "../../../components/Form";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({ className }) => {
  const dateFilter = process.env.REACT_APP_DATE_FILTER.split(',');
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [bitdenexPayList, setBitdenexPayList] = useState([]);
  const [date, setDate] = useState(dateFilter[0]);
  const [regionData, setRegionData] = useState([]);
  const [regionText, setRegionText] = useState("");
  const [regionValue, setRegionValue] = useState("");

  useEffect(() => {
    getRegionHandler();
  }, []);

  const getRegionHandler = async () => {
    try {
      const regionPayload = await requestHandler('region', 'post');
      setRegionData(regionPayload?.data?.data);
      const newDropDownValue = regionPayload?.data?.data?.map(x => {
        return { "id": x.id, "name": x.name }
      });
      newDropDownValue.filter((x) => {
        if (x?.id === parseInt(process.env.REACT_APP_EUROPE_REGION_VALUE)) {
          setRegionValue(x?.id);
          setRegionText(x?.name);
        }
      })
    }
    catch (e) {
    };
  };

  useEffect(() => {
    if (regionValue !== "") {
      getBitdenexPayDataHandler(regionValue);
    }
  }, [pageNo, regionValue, date]);

  const handlePageClick = () => {
    setPageNo(pageNo + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const getBitdenexPayDataHandler = async (id) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      page: pageNo,
      region: id,
      status: process.env.REACT_APP_DEPOSIT_CRYPTO_TRANSACTION_STATUS_SUCESS,
      interval: date.toLowerCase() === "day" ? parseInt(process.env.REACT_APP_DAILY_DATA) :
        date.toLowerCase() === "week" ? parseInt(process.env.REACT_APP_WEEKLY_DATA) :
          date.toLowerCase() === "month" ? parseInt(process.env.REACT_APP_MONTHLY_DATA) :
            date.toLowerCase() === "year" ? parseInt(process.env.REACT_APP_ANNUALY_DATA) : null
    };
    try {
      const bitdenexPayPayload = await requestHandler("actionWithdrawBitdenexPayCryptoList", "post", data, "jwt_token");
      setLoading(false);
      setBitdenexPayList(bitdenexPayPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Requests"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by Txn Id"
              type="text"
              name="search"
              icon="search"
            />
            <div className={styles.dropdown}>
              <OrderDropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={regionValue}
                setValue={setRegionValue}
                text={regionText}
                setText={setRegionText}
                options={regionData}
              />
            </div>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={date}
                setValue={setDate}
                options={dateFilter}
                small
              />
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Coin</div>
            <div className={styles.col}>From (CId)</div>
            <div className={styles.col}>To (CId)</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Main Wallet TxId</div>
            <div className={styles.col}>Txn Id</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={8} rowCount={12} />
            :
            bitdenexPayList?.length > 0 ?
              <>
                {
                  bitdenexPayList?.filter((data) => {
                    return data.txid.includes(searchField.toLowerCase())
                  }).map((x, index) => (
                    <Row item={x} key={index} />
                  ))}
                {bitdenexPayList?.length === (10 * pageNo) && <div className={styles.foot}>
                  <button className={cn("button-stroke button-small", styles.button)} onClick={() => { handlePageClick() }}>
                    <span>Load more</span>
                  </button>
                </div>
                }
              </>
              :
              "No data found"
          }
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
