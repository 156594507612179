import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import Icon from "../Icon";
import User from "./User";
import { useDispatch, useSelector } from "react-redux";
import { userDetailsCreator } from "../../actions/userDetails";

const Header = ({ onOpen, handleToggle, superadmin, isRootPath }) => {
  const [visible, setVisible] = useState(false);
  const storedSideMenu = localStorage.getItem("sideMenu");

  const dispatch = useDispatch();
  const { usersDetails } = useSelector((state) => state.userDetails);

  useEffect(() => {
    dispatch(userDetailsCreator());
  }, []);

  const handleClick = () => {
    onOpen();
    setVisible(false);
  };

  // console.log("usersDetails", usersDetails);

  return (
    <header className={styles.header}>
      <button className={styles.burger} onClick={() => handleClick()}></button>
      {/* <Search className={cn(styles.search, { [styles.visible]: visible })} /> */}
      <button
        className={cn(styles.buttonSearch, { [styles.active]: visible })}
        onClick={() => setVisible(!visible)}
      >
        <Icon name="search" size="24" />
      </button>
      {(superadmin && isRootPath) ? (
        <button className={styles.toggleButton} onClick={handleToggle}>
          {(storedSideMenu && JSON.parse(storedSideMenu) === true) ? "Miracle" : "Bitdenex"}
        </button>
      ) : null}
      <div className={styles.control} onClick={() => setVisible(false)}>
        {/* <Link className={cn("button", styles.button)} to="/products/add">
          <Icon name="add" size="24" />
          <span>Create</span>
        </Link> */}
        {/* <Messages className={styles.messages} /> */}
        {/* <Notification className={styles.notification} /> */}
        <User className={styles.user} usersDetails={usersDetails} />
      </div>
      {/* <div className={styles.btns}>
        <Link className={styles.link} to="/sign-in">
          Sign in
        </Link>
        <Link className={cn("button", styles.button)} to="/sign-up">
          Sign up
        </Link>
      </div> */}
    </header>
  );
};

export default Header;
