import React, { useEffect, useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import requestHandler from "../../../actions/httpClient";
import { addNotification } from "../../../components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { userDetailsCreator } from "../../../actions/userDetails";

const RecentPost = ({ className }) => {
  const [loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);
  const [order, setOrder] = useState("DSC");
  const { usersDetails } = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();

  let superadmin = Object.values(usersDetails).includes(process.env.REACT_APP_SUPER_ADMIN_ROLE);

  useEffect(() => {
    if (Object.entries(usersDetails).length === 0) {
      dispatch(userDetailsCreator());
    }
  }, []);


  useEffect(() => {
    getInprocessKycCustomerDetails();
  }, [pageRefreshFlag]);

  const getInprocessKycCustomerDetails = async () => {
    setLoading(true);
    try {
      let data = {
        signature: localStorage.getItem("signature"),
      };
      const usersPayload = await requestHandler("userInProcessKycList", "post", data, "jwt_token");
      setLoading(false);
      setUsersData(usersPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const updateUserHandler = async (userId, fname, lname, email_verified, kyc_verification, residence_verification, region, risk_profile, auth_enable, mobile_no, country_code, residence_address, notes) => {
    setLoading(true);
    var finalNumber;
    if (mobile_no?.length > 0) {
      let dummyNumber = mobile_no;
      let dummyPhoneNumber = dummyNumber.slice(country_code.length);
      finalNumber = dummyPhoneNumber;
    };
    const data = {
      userId: userId,
      fname: fname,
      lname: lname,
      email_verified: email_verified,
      kyc_verification: kyc_verification,
      residence_verification: residence_verification,
      signature: localStorage.getItem("signature"),
      region: region,
      risk_profile: risk_profile,
      auth_enable: auth_enable,
      mobile_no: finalNumber,
      country_code: country_code,
      residence_address: residence_address,
      notes: notes,
    };
    try {
      const updateUserPayload = await requestHandler("updateCustomer", "post", data, "jwt_token");
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      if (updateUserPayload && updateUserPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: updateUserPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
      }
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
    }
  };

  const handleOrder = (e) => {
    const sorted =
      order && order === "ASC"
        ? usersData.sort((a, b) =>
          a["region"] > b["region"] ? 1 : -1
        )
        : usersData.sort((a, b) =>
          a["region"] < b["region"] ? 1 : -1
        );
    const sortingType = order && order === "ASC" ? "DSC" : "ASC";
    setUsersData(sorted);
    setOrder(sortingType);
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Customers"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>C Id</div>
            <div className={styles.col}>Customer</div>
            <div className={styles.col}>U Id</div>
            <div className={(styles.col, styles.sortIcon)} onClick={() => handleOrder()}>{order === "DSC" ? "Region ▲" : "Region ▼"}</div>
            <div className={styles.col}>Registration Date</div>
            {superadmin && <div className={styles.col}>Action</div>}
          </div>
          {
            loading ? <CustomerListSkeleton colCount={6} rowCount={12} />
              : usersData?.map((x, index) => (
                <Row
                  item={x}
                  key={index}
                  updateUserHandler={updateUserHandler}
                  order={order}
                  superadmin={superadmin}
                />
              ))}
        </div>
      </Card>
    </>
  );
};

export default RecentPost;
