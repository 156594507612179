import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({
  item,
  index
}) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {index + 1}
      </div>
      <div className={styles.col}>
        {item}
      </div>
    </div>
  );
};

export default Row;
