import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Details from "./Details";
import NewModal from "../../../../components/NewModal";
import { getDigitsAfterDecimal, toLocaleStringDateFormat } from "../../../../components/helper";

const Row = ({ item }) => {

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className={styles.row} onClick={() => { handleOpenModal() }}>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.preview}>
              <img src={`${process.env.REACT_APP_IMAGE_URL}/${item.coinIcon}`} alt="Coin" />
            </div>
            <div className={styles.details}>
              <div
                className={styles.post}
                dangerouslySetInnerHTML={{ __html: item?.coinName?.toUpperCase() }}
              ></div>
              <div className={styles.text}>
                {item?.coinSymbol?.toUpperCase()}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            <div className={styles.box}>
              <div className={styles.number}>
                {item?.contract_id === null || item?.contract_id === undefined ? "---" : item?.contract_id}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.cell}>
            <div className={styles.box}>
              <div className={styles.number}>
                {item?.userId}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{getDigitsAfterDecimal(item?.stakeAmount, item?.amountDecimalPrecision)} {item?.coinSymbol?.toUpperCase()}</div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{item?.region}</div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{item?.annualPercentage}%</div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{item?.stakedDays === null || item?.stakedDays === undefined ? "---" : item?.stakedDays}/{item?.totalDays}</div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.details}>
            <div className={styles.product}>{toLocaleStringDateFormat(item?.createdAt)}</div>
          </div>
        </div>
      </div>
      <NewModal
        outerClassName={styles.outer}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
      >
        <Details item={item} />
      </NewModal>
    </>
  );
};

export default Row;
