import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Row from "./Row";
import requestHandler from "../../../actions/httpClient";
import { useEffect } from "react";
import { addNotification } from "../../../components/Notification";
import Form from "../../../components/Form";
import Pagination from "../../../components/Pagination";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import axios from "axios";

const intervals = ["Pending", "Verified", "Declined", "Cancelled"];
// const dateFilter = ["Week", "Month", "Year", "Day"];
const tokenOptions = ["ERC20", "TRC20", "BSC", "BNB"];

const RecentPost = ({ className }) => {
  const dateFilter = process.env.REACT_APP_DATE_FILTER.split(',');
  const [sorting, setSorting] = useState(intervals[0]);
  const [date, setDate] = useState(dateFilter[0]);
  const [allAddressData, setAllAddressData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState();
  // const [tokenValue, setTokenValue] = useState(tokenOptions[0]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    getAllAddress();
  }, [sorting, pageRefreshFlag, date, pageCount]);

  const getAllAddress = async () => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      pageno: pageCount,
      address_type_status:
        sorting.toLowerCase() === "pending" ? parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PENDING) :
          sorting.toLowerCase() === "processing" ? parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PROCESSING) :
            sorting.toLowerCase() === "verified" ? parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_VERIFIED) :
              sorting.toLowerCase() === "declined" ? parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_DECLINED) :
                sorting.toLowerCase() === "cancelled" ? parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_CANCELLED)
                  : null,
      interval: date.toLowerCase() === "day" ? parseInt(process.env.REACT_APP_DAILY_DATA) :
        date.toLowerCase() === "week" ? parseInt(process.env.REACT_APP_WEEKLY_DATA) :
          date.toLowerCase() === "month" ? parseInt(process.env.REACT_APP_MONTHLY_DATA) :
            date.toLowerCase() === "year" ? parseInt(process.env.REACT_APP_ANNUALY_DATA) : null,
    }
    try {
      const allAddressPayload = await requestHandler("allCustomerAddress", "post", data, "jwt_token");
      setLoading(false);
      setAllAddressData(allAddressPayload.data.message.data);
      setTotalPages(allAddressPayload.data.message.count);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const updateAddressHandler = async (userId, addressId, address_verification, tokenValue) => {
    setLoading(true);
    const data = {
      signature: localStorage.getItem("signature"),
      userId: userId,
      address_verification: address_verification,
      addressId: addressId,
      network_slug: tokenValue,
    };
    try {
      const updateAddressPayload = await requestHandler("updateCustomerAddress", "post", data, "jwt_token");
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      if (updateAddressPayload && updateAddressPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: updateAddressPayload?.data?.message[0]?.msg,
          type: 'Success'
        });
      }
    }
    catch (e) {
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
    }
  };

  const generateAmlReportHandler = async (address_id, userId) => {
    setLoading(true);
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    let data = {
      signature: localStorage.getItem("signature"),
      address_id: address_id,
      userId: userId,
    };
    try {
      const generateAmlReportPayload = await requestHandler("generateAmlReport", "post", data, "jwt_token");
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
      addNotification({
        title: 'Success',
        message: generateAmlReportPayload?.data?.message[0]?.msg,
        type: 'Success'
      });
    } catch (e) {
      addNotification({
        title: 'Error',
        message: e?.data?.message[0]?.msg,
        type: 'danger'
      });
      setLoading(false);
      setPageRefreshFlag(!pageRefreshFlag);
    }
  };

  // const getAmlReportHandler = async (amlReport) => {
  //   setLoading(true);
  //   try {
  //     const { data, status } = await axios.get(`${process.env.REACT_APP_AML_REPORT_URL}/reports/aml/${amlReport}/pdf?include_indicators=true&include_descriptions=true&transaction_data=true`, {
  //       headers: {
  //         'Content-Type': 'application/pdf',
  //         Authorization: 'Bearer ' + process.env.REACT_APP_AML_REPORT_TOKEN
  //       },
  //       responseType: 'blob',
  //     });
  //     if (status && status === 200) {
  //       const file = new Blob([data], { type: 'application/pdf' });
  //       var fileURL = window.URL.createObjectURL(file);
  //       window.open(fileURL, "_blank");
  //       // const a = document.createElement("a");
  //       // a.style = "display: none";
  //       // document.body.appendChild(a);
  //       // a.href = fileURL;
  //       // a.download = "Report";
  //       // a.click();
  //       // window.URL.revokeObjectURL(fileURL);
  //       setLoading(false);
  //     }
  //   }
  //   catch (error) {
  //     setLoading(false);
  //   };
  // };

  const handlePageChange = (page) => {
    setPageCount(page.selected + 1);
  };

  const addressSearchHandler = async (searchField) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      pageno: pageCount,
      address_type_status:
        sorting.toLowerCase() === "pending" ? parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PENDING) :
          sorting.toLowerCase() === "processing" ? parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_PROCESSING) :
            sorting.toLowerCase() === "verified" ? parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_VERIFIED) :
              sorting.toLowerCase() === "declined" ? parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_DECLINED) :
                sorting.toLowerCase() === "cancelled" ? parseInt(process.env.REACT_APP_WHITELIST_ADDRESS_STATUS_CANCELLED)
                  : null,
      interval: date.toLowerCase() === "day" ? parseInt(process.env.REACT_APP_DAILY_DATA) :
        date.toLowerCase() === "week" ? parseInt(process.env.REACT_APP_WEEKLY_DATA) :
          date.toLowerCase() === "month" ? parseInt(process.env.REACT_APP_MONTHLY_DATA) :
            date.toLowerCase() === "year" ? parseInt(process.env.REACT_APP_ANNUALY_DATA) : null,
      search_text: searchField,
      type: "uid",
    };
    try {
      if (data?.search_text === "") {
        setLoading(false);
        addNotification({
          title: "Alert",
          message: "Search field can not be empty",
          type: "danger",
        });
      }
      else {
        const addressSearchPayload = await requestHandler("allCustomerAddressSearch", "post", data, "jwt_token");
        setLoading(false);
        setAllAddressData(addressSearchPayload.data.message.data);
        setTotalPages(addressSearchPayload.data.message.count);
      };
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Please verify the entered details.",
        type: "danger",
      });
    }
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Address"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Form
              className={styles.form}
              value={searchField}
              setValue={setSearchField}
              onSubmit={handleSubmit}
              placeholder="Search by Customer Id"
              type="text"
              name="search"
              icon="search"
            />
            <div className={styles.control}>
              <button className={cn("button-small", styles.dropdown)} onClick={(e) => {
                e.preventDefault();
                addressSearchHandler(searchField);
              }}>
                <span>Submit</span>
              </button>
            </div>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={setSorting}
                options={intervals}
                small
              />
            </div>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={date}
                setValue={setDate}
                options={dateFilter}
                small
              />
            </div>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Coin</div>
            <div className={styles.col}>Customer Id</div>
            <div className={styles.col}>Address</div>
            <div className={styles.col}>Region</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Ownership</div>
            <div className={styles.col}>Scan Ownership</div>
            {sorting && sorting.toLowerCase() === "pending" && <div className={styles.col}>Action</div>}
            {(sorting && sorting.toLowerCase() === "declined" || sorting.toLowerCase() === "verified") && <div className={styles.col}>View Report</div>}
            {sorting && sorting.toLowerCase() === "pending" && <div className={styles.col}>Edit</div>}
          </div>
          {loading ? <CustomerListSkeleton colCount={8} rowCount={12} />
            :
            allAddressData?.length > 0 ?
              <>
                {allAddressData?.map((x, index) => (
                  <Row
                    item={x}
                    key={index}
                    updateAddressHandler={updateAddressHandler}
                    generateAmlReportHandler={generateAmlReportHandler}
                    sorting={sorting}
                  // tokenOptions={tokenOptions}
                  // tokenValue={tokenValue}
                  // setTokenValue={setTokenValue}
                  />
                ))}
                {/* {
                  allAddressData?.length === (10 * pageNo) &&
                  <div className={styles.customButton}>
                    <button className={cn("button-stroke button-small", styles.button)} onClick={() => { handleLoadMore() }}>
                      <span>Load more...</span>
                    </button>
                  </div>
                } */}
              </>
              :
              "No data found"
          }
        </div>
        {
          allAddressData?.length > 0 &&
          <Pagination
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        }
      </Card>
    </>
  );
};


export default RecentPost;
