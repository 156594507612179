import React, { useRef, useState } from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import TextInput from "../../../../components/TextInput";
import LoaderScreen from "../../../../components/LoaderScreen";
import SimpleReactValidator from "simple-react-validator";

const NewPost = ({
  loading,
  addBlockIpHandler
}) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [ipAddress, setIpAddress] = useState("");

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.post}>
        <div className={styles.details}>
          <div className={cn("title-purple", styles.title)}>Add Ip Address</div>
          <div className={styles.description}>
            <TextInput
              className={styles.field}
              label="IP Address"
              name="ipAddress"
              type="text"
              value={ipAddress}
              onChange={(e) => {
                const regex = /^(\d{0,3}\.){0,3}\d{0,3}(:\d*)?$/
                if (regex.test(e.target.value) || e.target.value === '') {
                  setIpAddress(e.target.value)
                }
              }}
              placeholder="Please enter a value"
              required
              onBlur={() => simpleValidator.current.showMessageFor("ipAddress")}
            />
            <span className={styles.danger}>
              {simpleValidator.current.message("ipAddress", ipAddress, "required")}
            </span>
          </div>

          <div className={styles.control}>
            <button className={cn("button", styles.button)} onClick={(e) => {
              e.preventDefault();
              if (simpleValidator.current.allValid()) {
                addBlockIpHandler(ipAddress);
              } else {
                simpleValidator.current.showMessages();
                forceUpdate(1);
              }
            }
            }>
              <span>Continue</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPost;
